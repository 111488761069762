import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import axios from "axios";
import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import DndComponent from "@ui/DndComponent";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Spinner from "@ui/Spinner";

import {
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { setLastReachedStep } from "@reducers/actCreationSlice";
import {
  selectFormikData,
  selectModalData,
  setFormikData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import {
  ActParty,
  ActStepProps,
  Document,
  Lawyers,
  PathMap,
  lawyerProps,
} from "@types";

import {
  addQueryParams,
  formatTimestamp,
  get,
  getPathMap,
  validateEmail,
} from "@utils";

const SendForSignatures = ({ setFormikState }: ActStepProps) => {
  const { actType, publicId } = useParams();
  const isDivorce = "divorce" === actType;
  const user = useSelector(selectUser);
  const modalData = useSelector(selectModalData);
  const formikData = useSelector(selectFormikData);
  const documentTitle =
    actType === "convention" ? labels.agreementsToSign : labels.documentsToSign;
  const [parties, setParties] = useState<ActParty[]>([]);
  const [documentsList, setDocumentsList] = useState<Document[]>([]);
  const [cguFile, setCguFile] = useState<any>();
  const [sortingDisabled, setSortingDisabled] = useState<boolean>(true);
  const [requests, setRequests] = useState<any[]>([]);
  const [sealedAllParties, setSealedAllParties] = useState<boolean>(false);
  const [lawyersData, setLawyersData] = useState<Lawyers[]>([]);
  const [lawyerEmails, setLawyerEmails] = useState<string[]>([]);
  const [initializing, setInitializing] = useState(true);
  const [metaData, setMetaData] = useState<Record<string, any>>({});
  const [processingPromise, setProcessingPromise] = useState<null | string>(
    null,
  );
  const [updatingLawyer, setUpdatingLawyer] = useState(false);
  const [readAllModified, setReadAllModified] = useState(false);
  const [loadingAction, setLoadingAction] = useState<null | string>(null);
  const [id, setId] = useState<number | null>(null);

  const pathMap: PathMap = getPathMap(actType, publicId);
  const thirdStepPath = pathMap[3]?.url || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reorderDocuments = (newOrder: Document[]) => {
    setDocumentsList(newOrder);

    if (!requests.includes("documents")) {
      setRequests((prevRequests) => [...prevRequests, "documents"]);
    }
  };

  const reorderParties = (newOrder: ActParty[]) => {
    setParties(newOrder);

    if (!requests.includes("parties")) {
      setRequests((prevRequests) => [...prevRequests, "parties"]);
    }
  };

  useEffect(() => {
    dispatch(
      setFormikData({
        hasChanges: 0 !== requests.length || readAllModified,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests, readAllModified]);

  useEffect(() => {
    // If an ID is stored this means we've previously created an act
    if (!publicId) {
      return;
    }

    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setMetaData({
          description: response.data.description,
          creationDate: response.data.creationDate,
          expirationDate: response.data.expirationDate,
          mustReadAllDocumentPages: response.data.mustReadAllDocumentPages,
          name: response.data.name,
          type: actType,
          publicId: response.data.publicId,
        });
        setId(response.data.id);

        initialValues.mustReadAllDocumentPages =
          response.data.mustReadAllDocumentPages;
        // If the act type is different from the one we're on, we redirect to the correct one
        const actTypeDcm = actTypeNameMap[response.data.businessType];
        const normalizedType =
          undefined !== actTypeDcm
            ? actTypeDcm
            : actTypeNameMap[response.data.type];

        if (normalizedType !== actType) {
          navigate(`/acts/${normalizedType}/${publicId}/signatures`);
        }

        const responseStep = response.data.metaDataValues.find(
          (item: any) => item.code === "wfStep",
        )?.value;

        dispatch(setLastReachedStep(responseStep));

        setInitializing(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // If an ID is stored this means we've previously created an act
    if (!publicId) {
      return;
    }

    setInitializing(true);

    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setMetaData({
          description: response.data.description,
          creationDate: response.data.creationDate,
          expirationDate: response.data.expirationDate,
          mustReadAllDocumentPages: response.data.mustReadAllDocumentPages,
          name: response.data.name,
          type: actType,
          publicId: response.data.publicId,
        });

        initialValues.mustReadAllDocumentPages =
          response.data.mustReadAllDocumentPages;

        setInitializing(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicId, actType]);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios
      .get(`/api/v1/signbooks/${publicId}/signatories`)
      .then((response) => {
        const normalizedData = response.data?.map((party: any) => {
          return {
            id: party.signatoryId,
            firstName: party.firstName,
            lastName: party.lastName,
            companyName: party.companyName,
            email: party.email,
            type: "TYPE_NATURAL" === party.personType ? "person" : "company",
            phone: party.mobileNumber.replace("+33", ""),
            phoneCode: "+33",
            birthDate: party.birthDate,
            lawyerId: party.advisor,
            isLocked: party.isLocked,
          };
        });

        const newPartyData = normalizedData.map((party: any, index: number) => {
          return {
            ...parties[index],
            ...party,
          };
        });

        setParties(newPartyData);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!publicId || user?.numCNBF === "") {
      return;
    }

    // GET request for lawyers
    axios
      .get(`/api/v1/signbooks/${publicId}/lawyers`)
      .then((response) => {
        const normalizedData = response.data.map(
          (lawyer: lawyerProps, index: number) => {
            const masterLawyer = lawyer.codeCNBF === user.numCNBF;

            return {
              id: index.toString(),
              codeCNBF: lawyer.codeCNBF,
              firstName: lawyer.firstName,
              lastName: lawyer.lastName,
              email: lawyer.email,
              master: masterLawyer,
              editor: masterLawyer,
              phone: lawyer.mobileNumber?.replace("+33", ""),
              phoneCode: "+33",
              type: "lawyer",
              advisingThePartyMessage: `${labels.advise}`,
              codeBarreau: response.data.codeBarreau,
              dateBarreau: response.data.dateBarreau,
            };
          },
        );

        initialValues.lawyersEmail = normalizedData.map(
          (lawyer: lawyerProps) => lawyer.email,
        );
        setLawyersData(normalizedData);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicId, user]);

  const hasSealing =
    actType && ["birth", "divorce", "digital"].includes(actType);
  const hasLawyers =
    actType && ["birth", "divorce", "digital"].includes(actType);

  const SortableParty = (props: any) => {
    const { party } = props;
    const { active, attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: props.id, disabled: sortingDisabled });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const handleIcon = active?.id === props.id ? "handle-active" : "handle";

    return (
      <div
        ref={setNodeRef}
        style={style}
        className="flex justify-between items-center mb-[24px]"
      >
        <div className="flex w-full items-center justify-between py-[5px]">
          <div className="flex items-center w-[173px]">
            {!sortingDisabled && (
              <span
                className="cursor-pointer mr-[14px]"
                {...attributes}
                {...listeners}
              >
                <Icon type={handleIcon} className="size-[24px]" />
              </span>
            )}
            <p>
              <Icon
                type={party.type === "person" ? "avatar" : "apartment"}
                className="size-[32px] mr-4"
                color="#000"
              />
            </p>
            {"company" === party.type ? (
              <p className="body-md">{party.companyName}</p>
            ) : (
              <>
                <p className="body-sm mr-1">{party.firstName}</p>
                <p className="body-sm">{party.lastName}</p>
              </>
            )}
          </div>
          <p className="flex justify-center body-md w-[173px]">{party.email}</p>
          {hasSealing ? (
            <div className="flex justify-end w-[173px]">
              {party.isLocked ? (
                <div className="flex mr-8">
                  <Icon
                    type="check"
                    className="size-[20px] text-green-500 mr-[10px]"
                  />
                  <span className="body-md">{labels.sealed}</span>
                </div>
              ) : (
                <Link
                  to={`/acts/${actType}/${publicId}/parties`}
                  className="flex items-end justify-end btn-secondary-emphasize rounded-[8px] px-[17px] py-[6px] group"
                >
                  <Icon
                    type="exclamation-triangle"
                    className="size-[20px] text-white mr-[10px] group-hover:text-white"
                  />
                  <span className="body-md">{labels.notSealed}</span>
                </Link>
              )}
            </div>
          ) : (
            <div className="w-[173px]"></div>
          )}
        </div>
      </div>
    );
  };
  const personTitleSection =
    actType === "convention" ? labels.clients : labels.signingParties;

  useEffect(() => {
    const allPartiesSealed = parties.every((party) => party.isLocked);
    setSealedAllParties(allPartiesSealed);
  }, [parties]);

  const initialValues = useMemo(() => {
    return {
      partiesEmails: parties.map((party) => party.email),
      lawyersEmail: lawyersData.map((lawyer) => lawyer.email),
      signingOrder: false,
      mustReadAllDocumentPages: isDivorce || false,
    };
  }, [isDivorce, lawyersData, parties]);

  useEffect(() => {
    setLawyerEmails(initialValues.lawyersEmail);
  }, [initialValues]);

  const stateCheckbox = parties.length < 2 ? "disabled" : "";
  const disabledText = parties.length < 2 ? "text-ea-gray-100" : "";
  const metaDataLoading = Object.keys(metaData).length === 0;

  useEffect(() => {
    if (publicId) {
      // GET request for uploaded documents
      axios
        .get(`/api/v1/signbooks/${publicId}/documents`)
        .then((response) => {
          const documents = response.data;
          if (documents.length > 0) {
            const cguDocuments: any[] = [];
            const otherDocuments: any[] = [];

            documents.map((document: Document, index: number) => {
              const formattedDocument = {
                id: document.documentUid,
                name:
                  document.documentName?.split("/")[1] || document.documentName,
                size: document.fileSize,
                order:
                  document.type === "CGU" ? 0 : document.order || index + 1,

                fixed: document.type === "CGU",
              };

              if (document.type === "CGU") {
                setCguFile(formattedDocument);
                cguDocuments.push(formattedDocument);
              } else {
                otherDocuments.push(formattedDocument);
              }

              return formattedDocument;
            });
            otherDocuments.sort((a: any, b: any) => a.order - b.order);

            setDocumentsList(otherDocuments);
            if (cguDocuments.length > 0) {
              setCguFile(cguDocuments[0]);
            }
          }
        })
        .catch((error) => {
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    }
  }, [publicId]);

  const overflowControl =
    documentsList.length > 3 ? "overflow-y-auto" : "pr-[8px]";

  const SortableDocument = (props: {
    data: Document;
    itemId: string;
    isFixed: boolean;
  }) => {
    const title = props.data.name;
    const itemId = props.itemId;
    const fixed = props.isFixed;

    const { active, attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: itemId, disabled: fixed });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const handleIcon = active?.id === itemId ? "handle-active" : "handle";

    return (
      <div
        className="flex items-center justify-between mb-[24px]"
        ref={setNodeRef}
        style={style}
      >
        <div className="flex">
          {documentsList.length > 1 && !fixed && (
            <span
              className="cursor-pointer mr-[14px]"
              {...attributes}
              {...listeners}
            >
              <Icon type={handleIcon} className="size-[24px]" />
            </span>
          )}
          <div className="flex">
            <img
              src={"/assets/pdf-icon.png"}
              alt={"pdf icon"}
              className="w-[18px] h-[22px] mr-[17px]"
            />
            <p className="body-md">{title}</p>
          </div>
        </div>
        <div className="flex items-center">
          <Icon
            type="check"
            color="#00C45A"
            className="w-[14px] h-[10px] mr-[20px]"
          />
          <Link
            to={addQueryParams(`/acts/${actType}/new/documents/preview`, {
              documentId: props.data.id,
              returnPath: `/acts/${actType}/${publicId}/signatures`,
              actId: publicId,
              actTitle: metaData.name,
              documentName: props.data.name,
              lawyer: user?.id ? "true" : "",
            })}
            className="btn-secondary body-md px-[15px] py-[6px] rounded-[8px] mr-2"
          >
            {labels.view}
          </Link>
        </div>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      if (isDivorce) {
        dispatch(showModal("divorceVerification"));
      }
      if (actType === "birth") {
        dispatch(setPageData({ actCreated: true, actId: id }));
        navigate(`/acts/birth`);
      }
    },
    validate: (values) => {
      const initialLaywers = JSON.stringify(lawyerEmails);
      const currentLawyers = JSON.stringify(values.lawyersEmail);

      dispatch(
        setFormikData({
          hasChanges:
            0 !== requests.length ||
            readAllModified ||
            initialLaywers !== currentLawyers,
        }),
      );
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setFormikState({
      formik: formik,
      isDirty: readAllModified,
      callback: () => {
        return updateMetaData(`/acts/${metaData.type}`);
      },
      step: 4,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readAllModified]);

  if (initializing) {
    return (
      <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
        <Spinner className="size-10" dark={true} />
      </div>
    );
  }

  const updateNewlySortedDocuments = () => {
    const dataToUpdate = documentsList.map((file) => ({
      documentUid: file.id,
      order: file.fixed ? 0 : file.order,
      documentType: "other",
    }));

    return axios
      .put(
        `/api/v1/signbooks/${metaData.publicId}/documents/signatureorder`,
        dataToUpdate,
      )
      .then(() => {})
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const updateNewlySortedParties = () => {
    const partiesOrder = parties.map((party, index) => ({
      signatoryCode: party.id,
      order: index + 1,
    }));

    return axios
      .put(
        `/api/v1/signbooks/${metaData.publicId}/signatories/signatureorder`,
        partiesOrder,
      )
      .then(() => {})
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  // Function for updating lawyers emails
  const changeLawyer = (indexToUpdate: number, formik: any) => {
    setUpdatingLawyer(true);
    // Find the lawyer which email we need to update
    const lawyerToUpdate: any = lawyersData[indexToUpdate];
    const updatedLawyer = {
      firstName: lawyerToUpdate.firstName,
      lastName: lawyerToUpdate.lastName,
      email: formik.values.lawyersEmail[indexToUpdate],
      codeCNBF: lawyerToUpdate.codeCNBF,
      mobileNumber: lawyerToUpdate.phone,
      codeBarreau: lawyerToUpdate.codeBarreau,
      dateBarreau: lawyerToUpdate.dateBarreau,
    };

    axios
      .put(
        `/api/v1/signbooks/${metaData.publicId}/lawyers/${updatedLawyer.codeCNBF}`,
        updatedLawyer,
      )
      .then(() => {
        setUpdatingLawyer(false);
        lawyersData[indexToUpdate].email = updatedLawyer.email;
        toast.success(labels.documentSuccesfullyUpdated, toastOptions);

        setLawyerEmails(formik.values.lawyersEmail);

        dispatch(
          setFormikData({
            hasChanges: 0 !== requests.length,
          }),
        );
      })
      .catch((error) => {
        setUpdatingLawyer(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const updateMetaData = (
    redirectTo: string = `/acts/${actType}${thirdStepPath}`,
  ) => {
    setLoadingAction("send");

    return axios
      .put(`/api/v1/signbooks/${metaData.publicId}`, {
        name: metaData.name,
        description: metaData.description,
        expirationDate: metaData.expirationDate,
        type: metaData.type,
        mustReadAllDocumentPages: metaData.mustReadAllDocumentPages,
      })
      .then(() => {
        setLoadingAction(null);
        navigate(redirectTo);
      })
      .catch((error) => {
        setLoadingAction(null);
        if (error.response.data.length > 0) {
          error.response.data.forEach((error: any) => {
            toast.error(error.message, toastOptionsError);
          });
          return;
        }

        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
      });
  };

  const getRedirectUrl = (data: any) => {
    return addQueryParams(`/acts/${actType}/new/documents/preview`, {
      documentId: "multiple",
      action: "sign",
      mustReadAll: readAllModified ? "true" : "",
      returnPath: `/acts/${actType}/${publicId}/signatures`,
      actTitle: data.title,
      lawyer: user?.id ? "true" : "",
    });
  };

  return (
    <FormikProvider value={formik}>
      <div className="px-[45px] py-[40px]">
        <h2 className="headline-lg mb-[20px]">{labels.sendForSigningTitle}</h2>
        <section className="mb-[20px]">
          {metaDataLoading ? (
            <Loader />
          ) : (
            <div className="flex items-center justify-between text-black mb-[5px]">
              <p className="headline-md">{metaData.name}</p>
              <p className="body-md">
                {`${labels.createdAt} ${formatTimestamp(metaData.creationDate, false)}`}
              </p>
            </div>
          )}
        </section>
        <section>
          <h3 className="headline-md mb-[12px]">{personTitleSection}</h3>
          <label
            className={`body-md custom-checkbox-container mb-[27px] ${disabledText}`}
          >
            <Field name="signingOrder">
              {({ field }: any) => {
                return (
                  <input
                    {...field}
                    type="checkbox"
                    className={`custom-checkbox ${stateCheckbox}`}
                    onClick={() => setSortingDisabled(field.value)}
                    disabled={parties.length < 2}
                  />
                );
              }}
            </Field>
            {labels.defineOrderOfSigning}
          </label>
          <div className="mb-[22px] mt-[27px]">
            <DndComponent items={parties} setItems={reorderParties}>
              {parties.map((party, index) => (
                <SortableParty party={party} key={index} id={party.id} />
              ))}
            </DndComponent>
          </div>

          {requests.includes("parties") && (
            <div className="flex items-center justify-between body-md bg-ea-lightblue py-2 px-3 mb-8 rounded">
              <p>{labels.orderSignaturesPrompt}</p>
              <Button
                className="btn-base btn-secondary body-md p-2 flex items-center justify-center"
                type="button"
                disabled={"parties" === processingPromise}
                onClick={() => {
                  setProcessingPromise("parties");

                  updateNewlySortedParties().then(() => {
                    setRequests((prevRequests) => {
                      return prevRequests.filter(
                        (request) => request !== "parties",
                      );
                    });
                    setProcessingPromise(null);
                  });
                }}
              >
                {"parties" === processingPromise && (
                  <Spinner className="mr-2 size-4" />
                )}
                {labels.save}
              </Button>
            </div>
          )}
        </section>
        {hasLawyers ? (
          <section>
            <h3 className="headline-md mb-[12px]">{labels.lawyers}</h3>
            {lawyersData.map((lawyer, index) => {
              const fieldName = `lawyersEmail.${index}`;
              return (
                <div
                  key={index}
                  className="flex items-center justify-between mb-[24px]"
                >
                  <div className="flex items-center w-[170px]">
                    {lawyer.image ? (
                      <img
                        className="w-[38px] h-[38px] rounded-[80.50px] mr-[12px]"
                        srcSet={lawyer.image}
                        alt="avatar"
                      />
                    ) : (
                      <Icon
                        type="avatar"
                        className="w-[38px] h-[38px] aspect-auto rounded-[80.50px] mr-[12px]"
                      />
                    )}
                    <div className="flex">
                      <p className="body-md mr-1">{lawyer.master}</p>
                      <p className="body-md mr-1">{lawyer.firstName}</p>
                      <p className="body-md">{lawyer.lastName}</p>
                    </div>
                  </div>
                  <div className="relative">
                    {undefined !== formik.values.lawyersEmail[index] && (
                      <Field
                        id={index}
                        name={fieldName}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.select();
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(fieldName, e.target.value);
                        }}
                        validate={validateEmail}
                        type="email"
                        className="flex body-md h-[34px] border py-1 px-2 text rounded-[4px] border-ea-gray-200 placeholder-black text-black ::placeholder"
                      />
                    )}
                    {get(formik.errors, fieldName) && (
                      <p className="text-ea-red text-[10px] absolute top-full left-0">
                        {get(formik.errors, fieldName)}
                      </p>
                    )}
                  </div>
                  <div className="inline-flex w-[145px] justify-end">
                    {lawyersData[index].email !==
                      formik.values.lawyersEmail[index] && (
                      <Button
                        className="btn-primary body-md p-2 rounded-[8px] flex items-center justify-center"
                        type="button"
                        disabled={
                          updatingLawyer ||
                          !!validateEmail(formik.values.lawyersEmail[index])
                        }
                        onClick={() => {
                          changeLawyer(index, formik);
                        }}
                      >
                        {updatingLawyer && <Spinner className="mr-2 size-4" />}
                        {labels.modify}
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}

            <p className="body-sm mb-[30px]">{labels.lawyerEditorNote}</p>
          </section>
        ) : null}
        <section>
          <h3 className="headline-md mb-[12px]">{documentTitle}</h3>

          <div className="mt-6">
            <div>
              <label
                className={`${(isDivorce || metaDataLoading) && "disabled text-ea-gray-100"} body-md custom-checkbox-container`}
              >
                <Field name="mustReadAllDocumentPages">
                  {({ field }: any) => {
                    return (
                      <input
                        {...field}
                        type="checkbox"
                        checked={metaData.mustReadAllDocumentPages || false}
                        className="custom-checkbox"
                        disabled={metaDataLoading || isDivorce}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;

                          setMetaData((prevMetaData) => ({
                            ...prevMetaData,
                            mustReadAllDocumentPages: isChecked,
                          }));

                          formik.setFieldValue(
                            "mustReadAllDocumentPages",
                            isChecked,
                          );

                          setReadAllModified(true);
                        }}
                      />
                    );
                  }}
                </Field>
                {labels.obligationToReadAllPages}
              </label>
            </div>
          </div>
          <div
            className={`mt-[25px] mb-[40px] md:w-[620px] max-h-[200px] ${overflowControl}`}
          >
            {cguFile && (
              <div className="flex justify-between items-center mt-[24px] first:mt-0 mb-[24px]">
                <span className="flex w-full items-center py-[5px]">
                  <img src="/assets/pdf-icon.png" alt="" />
                  <span className="ml-4 body-sm">{cguFile.name}</span>
                </span>

                <div className="flex items-center">
                  <Icon
                    type="check"
                    color="#00C45A"
                    className="w-[14px] h-[10px] mr-[20px]"
                  />
                  <Link
                    to={addQueryParams(
                      `/acts/${actType}/new/documents/preview`,
                      {
                        documentId: cguFile.id,
                        returnPath: `/acts/${actType}/${publicId}/signatures`,
                        actId: publicId,
                        actTitle: metaData.name,
                        documentName: cguFile.name,
                        lawyer: user?.id ? "true" : "",
                      },
                    )}
                    className="btn-secondary body-md px-[15px] py-[6px] rounded-[8px] mr-2"
                  >
                    {labels.view}
                  </Link>
                </div>
              </div>
            )}
            <DndComponent items={documentsList} setItems={reorderDocuments}>
              {documentsList.map((document, index) => (
                <SortableDocument
                  key={index}
                  data={document}
                  itemId={document.id}
                  isFixed={!!document.fixed}
                />
              ))}
            </DndComponent>
          </div>
          {requests.includes("documents") && (
            <div className="flex items-center justify-between body-md bg-ea-lightblue py-2 px-3 mb-8 rounded">
              <p>{labels.orderDocumentsPrompt}</p>
              <Button
                className="btn-base btn-secondary body-md p-2 flex items-center justify-center"
                type="button"
                disabled={"documents" === processingPromise}
                onClick={() => {
                  setProcessingPromise("documents");

                  updateNewlySortedDocuments().then(() => {
                    setRequests((prevRequests) => {
                      return prevRequests.filter(
                        (request) => request !== "documents",
                      );
                    });
                    setProcessingPromise(null);
                  });
                }}
              >
                {"documents" === processingPromise && (
                  <Spinner className="mr-2 size-4" />
                )}
                {labels.save}
              </Button>
            </div>
          )}
        </section>
        <div className="flex justify-between">
          <Button
            className="btn-base btn-secondary flex items-center justify-center"
            type="button"
            onClick={(event) => {
              if (!formik.isValid) {
                event.preventDefault();
              }

              if (formikData.hasChanges) {
                dispatch(
                  setModalData({
                    ...modalData,
                    redirectTo: `/acts/${actType}${thirdStepPath}`,
                  }),
                );
                dispatch(showModal("cancel"));
                return;
              }

              navigate(`/acts/${actType}${thirdStepPath}`);
            }}
          >
            {labels.previous}
          </Button>
          {actType === "convention" ? (
            <Button
              type="submit"
              className="btn-base btn-primary flex items-center justify-center"
              data-testid="send-and-continue"
              disabled={"send" === loadingAction}
              onClick={() => {
                updateMetaData(getRedirectUrl(metaData));
              }}
            >
              {"send" === loadingAction && <Spinner className="mr-2 size-4" />}
              {labels.signAndSend}
            </Button>
          ) : (
            <Button
              type="submit"
              className="btn-base btn-primary flex items-center justify-center"
              disabled={
                !formik.isValid ||
                !sealedAllParties ||
                0 !== requests.length ||
                "send" === loadingAction
              }
              onClick={() => {
                if (readAllModified) {
                  setLoadingAction("send");
                  updateMetaData(`/acts/${metaData.type}`);
                  return;
                }

                formik.handleSubmit();
              }}
              data-testid="send-for-signing"
            >
              {"send" === loadingAction && <Spinner className="mr-2 size-4" />}
              {labels.sendForSigning}
            </Button>
          )}
        </div>
      </div>
    </FormikProvider>
  );
};

export default SendForSignatures;
