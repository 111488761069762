import { useNavigate } from "react-router-dom";

import { TabsProps } from "@types";

const TabButtons = ({ tab, setTab, tabsList, pillMap = {} }: TabsProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex gap-5 justify-between self-start md:mt-14 text-base font-medium text-ea-gray-300 max-md:flex-wrap"
      data-testid="tab-buttons-container"
    >
      {tabsList.map((tabListData, index) => {
        if (tabListData.hidden) {
          return null;
        }

        const isActiveTabStyle =
          tab === tabListData.id ? "text-black active-tab__line" : "";

        const hasPill = tabListData?.type && pillMap?.[tabListData.type];
        const hasNewDocumentStyle =
          hasPill || tabListData.hasExpiringSoon ? (
            <span
              role="presentation"
              className="absolute bg-ea-red w-[12px] h-[12px] top-[7px] right-[-12px] rounded-[50%]"
            ></span>
          ) : null;

        return (
          <div
            key={index}
            className={`py-2 cursor-pointer relative ${isActiveTabStyle}`}
            onClick={() => {
              const tabID = tabListData.id;
              const tabURL = tabsList[tabID]?.url;

              setTab(tabID);

              if (tabURL) {
                navigate(tabURL);
              }
            }}
            data-testid={tabListData.testId}
          >
            {hasNewDocumentStyle}
            {tabListData.title}
          </div>
        );
      })}
    </div>
  );
};

export default TabButtons;
