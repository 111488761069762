import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Filters from "@components/filters/Filters";
import Layout from "@components/layout/Layout";
import DocumentForSigningTable from "@components/tables/DocumentsForSigningTable";
import DocumentsForValidatingTable from "@components/tables/DocumentsForValidatingTable";
import PartsToBeSealedTable from "@components/tables/PartsToBeSealedTable";
import PaymentToBeDoneTable from "@components/tables/PaymentToBeDoneTable";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Notifications from "@ui/Notifications";
import Pagination from "@ui/Pagination";
import TabButtons from "@ui/TabButtons";
import TabContent from "@ui/TabContent";

import { labels } from "@constants";

import { FilterProps } from "@types";

const RequiredAction = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const location = useLocation();
  const tabUrl = location.pathname.split("/").pop();
  const tabsList = [
    {
      title: labels.forSigningTabLabel,
      id: 0,
      url: "sign-documents",
      hasExpiringSoon: true,
      testId: "sign-documents-tab",
    },
    {
      title: labels.forSealingTabLabel,
      url: "seal-documents",
      id: 1,
      testId: "seal-documents-tab",
    },
    {
      title: labels.forValidatingTabLabel,
      id: 2,
      url: "validate-documents",
      testId: "validate-documents-tab",
    },
    {
      title: labels.forPaymentsToBeMadeTabLabel,
      id: 3,
      url: "payments",
      hasExpiringSoon: true,
      testId: "payments-tab",
    },
  ];
  const activeTabId = tabsList.find((tab) => tab.url === tabUrl)?.id || 0;
  const [activeTab, setActiveTab] = useState<number>(activeTabId);

  // Might be used when backend is implemented
  // eslint-disable-next-line
  const [filters, setFilters] = useState<FilterProps>({
    selected: [],
    startDate: "",
    endDate: "",
    partyName: "",
    lawyerName: "",
    keyword: "",
  });

  useEffect(() => {
    setShowFilters(false);
    setFilters({
      selected: [],
      startDate: "",
      endDate: "",
      partyName: "",
      lawyerName: "",
      keyword: "",
    });
  }, [activeTab]);

  const tables = [
    <DocumentForSigningTable />,
    <PartsToBeSealedTable />,
    <DocumentsForValidatingTable />,
    <PaymentToBeDoneTable />,
  ];

  return (
    <Layout sidebar={true} backgroundColor="bg-stone-50">
      <div className="flex flex-col h-[calc(100%-77px)] pb-20 px-[54px] pt-[38px] relative">
        <Notifications className="absolute top-[50px] md:top-[7px] left-1/2 -translate-x-2/4 w-[90%] md:w-[653px] xl:w-[800px] z-10" />
        <h1 className="mt-1 text-xl font-bold text-black max-md:max-w-full">
          Action requise
        </h1>

        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            {/* Tabs */}
            <TabButtons
              tab={activeTab}
              setTab={setActiveTab}
              tabsList={tabsList}
            />

            {/* Body of tabs */}
            <div className="flex flex-col pb-10 bg-white rounded-lg max-md:max-w-full">
              <div className="flex flex-col pt-[30px] font-medium max-md:px-5 max-md:max-w-full">
                {/* Filters */}
                <div className="flex gap-5 justify-between px-6 w-full mb-[14px] max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                  <div className="flex gap-2.5 items-center self-start">
                    <div className="relative">
                      <Icon
                        type="search"
                        color="black"
                        className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                      />
                      <input
                        type="text"
                        className="flex pl-[47px] pr-[10px] py-2 min-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                        placeholder={labels.titleSearch}
                      />
                    </div>
                    <Button
                      onClick={() => setShowFilters(!showFilters)}
                      className="flex items-center my-auto text-sm text-black"
                    >
                      <Icon
                        type="filters"
                        color="#000"
                        className="w-[18px] h-[18px] mr-[12px]"
                      />
                      {labels.filtersAndAdvancedSearch}
                    </Button>
                  </div>
                  <Button className="justify-center px-5 py-[5px] text-sm text-black whitespace-nowrap bg-white rounded-lg border border-black border-solid max-md:pr-5 btn-secondary">
                    {labels.export}
                  </Button>
                </div>

                {/* Filters */}
                {showFilters && (
                  <Filters
                    setShowFilters={setShowFilters}
                    setFilters={setFilters}
                  />
                )}

                <Pagination totalPages={0} />

                <TabContent activeTab={activeTab} data={tables} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequiredAction;
