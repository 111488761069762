import { useRef } from "react";
import { useSelector } from "react-redux";

import Spinner from "@ui/Spinner";

import { selectLoading } from "@reducers/tracesSlice";

import { TablePropsHistory } from "@types";

const PlainTable = ({ rows, columns, tableType }: TablePropsHistory) => {
  const overflowClass = rows.length > 5 ? "overflow-y-scroll" : "";
  const tableBody = useRef<HTMLTableSectionElement>(null);
  const loading = useSelector(selectLoading);

  const getWidthClasses = (
    index: number,
    tableType: string,
    isHeader: boolean,
  ) => {
    switch (tableType) {
      case "invoiceHistory":
        switch (index) {
          case 0:
            return "w-[19%] justify-start";
          case 2:
            return isHeader ? "w-[22%] flex-grow" : "w-[21%] flex-grow";
          case 3:
            return "w-[15%] justify-start";
          case 4:
            return "w-[10%] justify-start";
          default:
            return "w-[35%] justify-start";
        }
      case "actHistory":
        switch (index) {
          case 0:
            return "w-[20%]";
          case 1:
            return "w-[60%] flex-grow";
          case 2:
            return isHeader ? "w-[25%]" : "w-[25.5%]";
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const renderedHeaders = columns.map((column, index) => {
    if (column.header) {
      return <th key={column.label}>{column.header()}</th>;
    }

    const labelClass = column.labelClass || "";

    return (
      <th
        key={column.label}
        className={`py-[10px] body-lg ${getWidthClasses(
          index,
          tableType,
          true,
        )} flex justify-start ${labelClass}`}
      >
        {column.label}
      </th>
    );
  });

  const renderedRows = rows.map((row, index) => {
    // Apply grey background color to the row if its index is even
    const rowClassName = index % 2 === 0 ? "bg-gray-50" : "";

    const renderedCells = columns.map((column, index) => {
      return (
        <td
          key={column.label}
          className={`${column.className} ${getWidthClasses(
            index,
            tableType,
            false,
          )} flex `}
        >
          {column.render(row)}
        </td>
      );
    });

    return (
      <tr
        className={`hover:bg-ea-lightblue w-[98.5%] pr-1 h-[34px] flex justify-between items-center firefox:w-firefox-row ${rowClassName}`}
        // This is done, because React needs a unique key for each element in a list, and currently we have no id for each row
        // in real life, if it is possible we can use the id of the record
        key={`index-${index}-${new Date().getTime()}`}
      >
        {renderedCells}
      </tr>
    );
  });

  return (
    <div className="relative">
      <table className="overflow-auto w-[100%]" id="historyTable">
        <thead className="text-left text-neutral-500 relative flex after:content-[''] after:w-[97%] after:h-[1px] after:bg-ea-gray-200 after:absolute after:bottom-0 after:left-0">
          <tr className="w-[96%] flex justify-between">{renderedHeaders}</tr>
        </thead>

        <tbody
          className={`block h-[200px] overflow-auto ${overflowClass}`}
          ref={tableBody}
        >
          {renderedRows}
        </tbody>
      </table>

      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-black/20 p-4 rounded-lg">
            <Spinner className="size-7" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlainTable;
