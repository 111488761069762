import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ButtonDropdown from "@ui/ButtonDropdown";
import Icon from "@ui/Icon";
import Pagination from "@ui/Pagination";
import Spinner from "@ui/Spinner";
import Table from "@ui/Table";

import {
  axiosErrorMessages,
  dataForPartiesTable,
  toastOptionsError,
} from "@constants";
import { labels } from "@constants";

import {
  selectPageData,
  selectPostState,
  setModalData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { PartiesTableProps, SignatoriesTableProps } from "@types";

import { formatLabel } from "@utils";

const PartiesTable = ({ keyword }: PartiesTableProps) => {
  const dispatch = useDispatch();
  const data = useSelector(selectPageData);
  const [parties, setParties] = useState<SignatoriesTableProps[]>([]);
  const [lawyerData, setLawyerData] = useState([]);
  const [initializing, setInitializing] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const editedState = useSelector(selectPostState);

  const fetchContacts = (page: number) => {
    const keywordParam = keyword !== "" ? `&keyword=${keyword}` : "";

    axios
      .get(`/api/v1/contacts?page=${page}${keywordParam}`)
      .then((response) => {
        if (!response.data?.users) {
          return;
        }

        setParties(response.data.users);
        setTotalPages(Math.ceil(response.data.total / 10));

        setInitializing(false);
        dispatch(setPostState(false));
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  useEffect(() => {
    fetchContacts(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, keyword]);

  useEffect(() => {
    if (editedState) {
      fetchContacts(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedState, page]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.party) {
      const updatedData = data.party;
      const indexToUpdate = data.index;

      setParties((prevData: any) => {
        const updatedEntities = [...prevData];
        updatedEntities[indexToUpdate] = updatedData;

        return updatedEntities;
      });
    }

    if (data.lawyerData) {
      setLawyerData(data.lawyerData);
    }

    dispatch(setModalData(null));
  }, [data, dispatch]);

  const columns = [
    {
      label: labels.party,
      render: (data: SignatoriesTableProps) => {
        const profileIconType = (type: string) => {
          if (type === "TYPE_NATURAL") {
            return "F" === data.gender ? "profile-female" : "profile-male";
          }

          return "apartment";
        };

        return (
          <div className="flex justify-start items-center">
            <Icon
              className="size-8 mr-[16px]"
              type={profileIconType(data.type)}
            />
            <div>
              {"TYPE_NATURAL" === data.type
                ? `${data.firstname} ${data.lastname}`
                : `${data.companyName}`}

              {"TYPE_LEGAL" === data.type && (
                <p className="body-sm">
                  {formatLabel(
                    labels.representedBy,
                    `${data.firstname} ${data.lastname}`,
                  )}
                </p>
              )}
            </div>
          </div>
        );
      },
      className: "body-lg py-[15px] h-[90px]",
    },
    {
      label: labels.email,
      render: (data: SignatoriesTableProps) => data.email,
    },
    {
      label: labels.phone,
      render: (data: SignatoriesTableProps) => data.mainPhoneNumber,
    },
    {
      label: "",
      render: (data: SignatoriesTableProps) => {
        const index = parties.findIndex((party) => party.id === data.id);

        const buttonOptions = [];

        if (parties && parties[index]?.advisedBy?.[0]?.id === "") {
          // remove the add new lawyer option
          buttonOptions.unshift({
            label: labels.addNewLawyer,
            onClick: () => {
              const preparedData = {
                party: dataForPartiesTable[index],
                lawyerData: lawyerData,
                index: index,
              };
              dispatch(setModalData(preparedData));
              dispatch(showModal("addLawyerCounselor"));
            },
          });
        }

        return (
          <ButtonDropdown
            buttonLabel={labels.modify}
            options={buttonOptions}
            iconType="pencil"
            onClick={(event) => {
              const $closestCell = event.target.closest("[data-index]");

              const index = parseInt($closestCell.dataset.index);

              if (isNaN(index) || !parties) {
                return;
              }

              const preparedData = {
                party: parties[index],
                fromContacts: true,
              };

              dispatch(setModalData(preparedData));
              dispatch(showModal("editParty"));
            }}
          />
        );
      },
      className: "body-lg py-[15px] flex justify-end pr-[40px]",
    },
  ];

  const sortableColumns = [
    {
      label: labels.party,
      rowKey: "firstname",
    },
    {
      label: labels.lastActivity,
      rowKey: "lastActivity",
      dataType: "date",
      dataFormat: "longDate",
    },
  ];

  if (!parties) {
    return null;
  }

  return initializing ? (
    <div className="flex justify-center py-10">
      <Spinner className="size-10" dark={true} />
    </div>
  ) : (
    <>
      <Pagination totalPages={totalPages} setPage={setPage} />
      <Table
        columns={columns}
        rows={parties}
        sortableColumns={sortableColumns}
      />
    </>
  );
};

export default PartiesTable;
