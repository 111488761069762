import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import {
  selectModalData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { Lawyers, SignatoriesParties } from "@types";

const AddLawyerCounselor = () => {
  const [advancedSearchResultsVisible, setAdvancedSearchResultsVisible] =
    useState(false);
  const [advancedSearchResults, setAdvancedSearchResults] = useState<Lawyers[]>(
    [],
  );
  const [error, setError] = useState("");
  const [openFiltersDropdown, setOpenFiltersDropdown] = useState(false);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const data = useSelector(selectModalData);

  useEffect(() => {
    const hideDropdown = () => {
      setOpenFiltersDropdown(false);
    };

    window.addEventListener("click", hideDropdown);

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  });

  // Handles onChange of the Formik Field - when the user types in the input field
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any,
    fieldName: string,
  ) => {
    const { value } = e.target;
    const isFieldEmpty = value.trim() === "";

    // Open/closes the dropdown of the current field
    setOpenFiltersDropdown(!isFieldEmpty);

    formik.setFieldValue(fieldName, value);

    // Close the advanced search dropdown when user types in the search field

    if (fieldName === "search") {
    }
  };

  // Function to set the formik values when a lawyer is selected from the dropdown
  const handleFieldValueChange = (formik: any, result: Lawyers) => {
    formik.setFieldValue("search", `${result.firstName} ${result.lastName}`);
    formik.setFieldValue("newLawyerId", result.id);
    formik.setFieldValue("firstName", result.firstName);
    formik.setFieldValue("lastName", result.lastName);
  };

  // Function to remove party from advisingPartyName array
  const removePartyFromLawyers = (
    lawyerData: Lawyers[],
    partyIdToRemove: string,
  ) => {
    return lawyerData.map((lawyer: Lawyers) => ({
      ...lawyer,
      advisingPartyName: lawyer.advisingPartyName.filter(
        (party: { id: string; name: string }) => party.id !== partyIdToRemove,
      ),
    }));
  };

  // Function to add party to advisingPartyName array of a new lawyer
  const addPartyToNewLawyer = (
    lawyerData: Lawyers[],
    lawyerId: string,
    party: { id: string; name: string },
  ) => {
    const existingLawyer = lawyerData.find(
      (lawyer: Lawyers) => lawyer.id === lawyerId,
    );

    if (!existingLawyer) {
      let newLawyer = [].find((lawyer: Lawyers) => lawyer.id === lawyerId);

      if (newLawyer) {
        let updatedLawyerData = [...lawyerData, newLawyer];
        return updatedLawyerData;
      }
    } else {
      let updatedLawyerData = lawyerData.map((lawyer: Lawyers) => {
        if (lawyer.id === lawyerId) {
          return {
            ...lawyer,
            advisingPartyName: [...lawyer.advisingPartyName, party],
          };
        }
        return lawyer;
      });

      // Check if advisingPartyName length is zero and remove the lawyer if true
      updatedLawyerData = updatedLawyerData.filter(
        (lawyer: Lawyers) => lawyer.advisingPartyName.length > 0,
      );
      return updatedLawyerData;
    }
  };

  // Function to filter lawyers based on the search criteria
  function filterLawyers(searchValue: string) {
    const searchTerm = searchValue.toLowerCase();
    return [].filter((result: Lawyers) => {
      return (
        result.firstName?.toLowerCase().includes(searchTerm) ||
        result.lastName?.toLowerCase().includes(searchTerm) ||
        result.codeCNBF?.includes(searchTerm) ||
        result.email.toLowerCase().includes(searchTerm)
      );
    });
  }

  const initialValues = {
    newLawyerId: "",
    search: "",
    firstName: "",
    lastName: "",
    codeCNBF: "",
    email: "",
  };

  return (
    <Modal title={labels.newLawyer} width={450}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          // If the search field is empty, close the modal and do not change the current lawyer
          if (values.search.trim().length === 0) {
            dispatch(setModalData(null));
            dispatch(hideModal());
          }

          // If the user has typed something, but has not selecetd an existing lawyer, show an error
          if (values.newLawyerId === "") {
            setError(`${labels.invalidLawyer} ${labels.pleaseSelectLawyer}`);
            return;
          }
          const { lawyerData, party } = data;

          // Function to get the party name based on the type of the party
          const partyName = (party: SignatoriesParties) => {
            return party.type === "TYPE_LEGAL"
              ? `${party.socialReason}`
              : `${party.lastName} ${party.firstName}`;
          };

          // Remove party from advisingPartyName array of all lawyers
          const updatedLawyerData = removePartyFromLawyers(
            lawyerData,
            party.id,
          );

          // Add party to advisingPartyName array of the new lawyer
          const newLawyerData = addPartyToNewLawyer(
            updatedLawyerData,
            values.newLawyerId,
            {
              id: party.id,
              name: `${partyName(party)} `,
            },
          );

          // Create updated data about the party and the lawyers
          const updatedData = {
            ...data,
            party: {
              ...data.party,
              advisedBy: [
                {
                  id: values.newLawyerId,
                  name: `${values.firstName} ${values.lastName}`,
                },
              ],
            },
            lawyerData: newLawyerData,
          };

          dispatch(setPageData(updatedData));
          dispatch(setModalData(null));
          dispatch(hideModal());
        }}
      >
        {(formik) => (
          <Form>
            <>
              <div className="relative mt-4 mb-5" ref={searchInputRef}>
                {!advancedSearchResultsVisible ? (
                  // Search
                  <>
                    <Icon
                      type="search"
                      className="absolute top-[9px] left-[10px] size-[15px]"
                    />
                    <Field
                      type="text"
                      name="search"
                      placeholder={labels.searchByNameSurnameEmailOrCnbf}
                      className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputEvent = e.nativeEvent as InputEvent;
                        if (
                          inputEvent.inputType === "deleteContentBackward" &&
                          formik.values.newLawyerId
                        ) {
                          formik.resetForm();
                          formik.setFieldValue("newLawyerId", "");
                          return;
                        }
                        setError("");
                        return handleFieldChange(e, formik, "search");
                      }}
                    />

                    {/* List with results */}
                    {openFiltersDropdown && (
                      <div className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px]">
                        <ul className="max-h-[150px] w-full overflow-auto">
                          {filterLawyers(formik.values.search).map(
                            (result: Lawyers, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                                onClick={() => {
                                  handleFieldValueChange(formik, result);
                                  formik.setFieldValue(
                                    "newLawyerId",
                                    result.id,
                                  );
                                  setError("");
                                }}
                              >
                                <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                                  {result.firstName} {result.lastName}
                                </p>
                                <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                                  {result.organization}
                                </p>
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  // Results
                  <>
                    <Button
                      type="button"
                      className="w-full text-left"
                      onClick={(e) => {
                        e.preventDefault();
                        setAdvancedSearchResultsVisible(false);
                      }}
                    >
                      <Icon
                        type="advanced-search"
                        className="absolute top-[9px] left-[10px] size-[15px]"
                      />
                      <Field
                        type="text"
                        name="search"
                        disabled
                        value={`${formik.values.firstName} ${formik.values.lastName}`}
                        className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs bg-white"
                      />
                    </Button>
                    {advancedSearchResults.length > 1 ? (
                      <div className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px]">
                        <ul className="max-h-[150px] w-full overflow-auto">
                          {advancedSearchResults.map((result, index) => (
                            <li
                              key={index}
                              className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                              onClick={() => {
                                formik.resetForm();
                                handleFieldValueChange(formik, result);
                                formik.setFieldValue("newLawyerId", result.id);
                                setAdvancedSearchResults([]);
                                setError("");
                              }}
                            >
                              <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                                {result.firstName} {result.lastName}
                              </p>
                              <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                                {result.organization}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      advancedSearchResults.length === 0 && (
                        <div className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px]">
                          <p className="flex items-center justify-between py-2 px-[30px] cursor-default">
                            {labels.noResultsFound}
                          </p>
                        </div>
                      )
                    )}
                  </>
                )}
                {error.length > 0 && (
                  <span className="text-[10px] max-w-[293px] text-ea-red">
                    {error}
                  </span>
                )}
              </div>
            </>

            <div className="flex items-center justify-between">
              <Button
                type="button"
                className="btn-secondary body-md rounded-[8px] py-[6px] px-[18px]"
                onClick={() => {
                  formik.resetForm();
                  dispatch(setPageData(null));
                  dispatch(hideModal());
                }}
              >
                {labels.cancel}
              </Button>
              <Button
                type="submit"
                className="btn-primary body-md rounded-[8px] py-[6px] px-[18px]"
              >
                {labels.addThisLawyer}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLawyerCounselor;
