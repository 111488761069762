import { useFormik } from "formik";
import { useState } from "react";

import Layout from "@components/layout/Layout";
import PartiesTable from "@components/tables/PartiesTable";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Notifications from "@ui/Notifications";

import { labels } from "@constants";

const Signatures = () => {
  const [keyword, setKeyword] = useState("");

  const formik = useFormik({
    initialValues: {
      searchByName: "",
      searchByEmail: "",
    },
    onSubmit: (values) => {
      setKeyword(values.searchByName || values.searchByEmail);
    },
  });

  return (
    <Layout sidebar={true} backgroundColor="bg-stone-50">
      <div className="flex flex-col h-[calc(100%-77px)] pb-20 px-[54px] pt-[38px] bg-stone-50 relative">
        <Notifications className="absolute top-[50px] md:top-[7px] left-1/2 -translate-x-2/4 w-[90%] md:w-[653px] xl:w-[800px] z-10" />
        <h1 className="mt-1 mb-14 text-xl font-bold text-black max-md:max-w-full">
          {labels.signatures}
        </h1>

        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            {/* Body of tabs */}
            <div className="flex flex-col pb-10 bg-white rounded-lg max-md:max-w-full">
              <div className="flex flex-col pt-[30px] font-medium max-md:px-5 max-md:max-w-full">
                {/* Filters */}
                <div className="flex gap-5 justify-between px-6 w-full mb-[14px] max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                  <form
                    className="flex gap-2.5 items-center self-start"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="flex flex-col">
                      <label htmlFor="title" className="body-sm mb-[10px]">
                        {labels.surname}
                      </label>
                      <div className="relative">
                        <Icon
                          type="search"
                          color="black"
                          className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                        />
                        <input
                          name="searchByName"
                          type="text"
                          className="flex pl-[47px] pr-[10px] py-2 min-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                          onChange={formik.handleChange}
                          value={formik.values.searchByName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="title" className="body-sm mb-[10px]">
                        {labels.email}
                      </label>
                      <div className="relative">
                        <Icon
                          type="search"
                          color="black"
                          className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                        />
                        <input
                          name="searchByEmail"
                          type="text"
                          className="flex pl-[47px] pr-[10px] py-2 min-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                          onChange={formik.handleChange}
                          value={formik.values.searchByEmail}
                        />
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        setKeyword("");
                      }}
                      type="button"
                    >
                      <Icon
                        type="refresh"
                        color="#7F7F7F"
                        className="w-[16px] h-[16px] mr-[16px] mt-7"
                      />
                    </Button>
                    <Button
                      type="submit"
                      className="btn-base btn-secondary-emphasize mt-6"
                    >
                      {labels.search}
                    </Button>
                  </form>
                </div>
                <PartiesTable key={keyword} keyword={keyword} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signatures;
