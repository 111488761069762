import axios from "axios";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { PartyForm } from "@components/fragments/party-form/PartyForm";

import Button from "@ui/Button";
import FieldDropdown from "@ui/FieldDropdown";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  signatoriesLawyerDivorceTypeList,
  signatoriesPartyTypeList,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { setValidatedData } from "@reducers/actCreationSlice";
import {
  selectPageData,
  setModalData,
  setPageData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { selectPartyForm } from "@reducers/partyFormSlice";
import { selectPage, setPage } from "@reducers/tracesSlice";

import { Document, Lawyers, PrepopulatedDataForSearchParties } from "@types";

import { formatDateBirth, formatLabel, isPastDate } from "@utils";
import getParty from "@utils/api/searchPartyApi";
import { frenchPhoneNumberRegex, nameRegex } from "@utils/regex";

type PhysicalPersonValues = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  phone: string;
  countryCode: string;
  advisedBy: string;
  advisedByName: string;
  advisedBySurname: string;
  advisedByEmail: string;
  advisedByPhone: string;
  toBeSealedBy: string;
  advisePartyCheckboxStatus: boolean;
  validatePartyCheckboxStatus: boolean;
  signatureInPresence: boolean;
  adviseLawyerId: string;
  validateLawyerId: string;
};

type CompanyValues = {
  sirenNumber: string;
  socialReason: string;
  firstName: string;
  lastName: string;
  phone: string;
  countryCode: string;
  advisedBy: string;
  advisedByName: string;
  advisedBySurname: string;
  advisedByEmail: string;
  advisedByPhone: string;
  toBeSealedBy: string;
  advisePartyCheckboxStatus: boolean;
  validatePartyCheckboxStatus: boolean;
  signatureInPresence: boolean;
  adviseLawyerId: string;
  validateLawyerId: string;
};

type Values = PhysicalPersonValues & CompanyValues;

type SearchOrCreatePartiesProps = {
  setShowAddParties: (showAddParties: boolean) => void;
};

const SearchOrCreateParties = ({
  setShowAddParties,
}: SearchOrCreatePartiesProps) => {
  const [dataForLawyers, setDataForLawyers] = useState<any[]>([]);
  const [dataForParty, setDataForParty] = useState<any[]>([]);
  // Used to send true or false in partyForm to know if the party is from search or not
  const [dataPartyForForm, setDataPartyForForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [files, setFiles] = useState<Document[]>([]);
  const [uploadFileControl, setUploadFileControl] = useState("hidden");
  const [populatedData, setPopulatedData] =
    useState<PrepopulatedDataForSearchParties | null>(null);
  const [simpleSearchPartyVisible, setSimpleSearchPartyVisible] =
    useState(false);
  const [simpleSearchPartyValue, setSimpleSearchPartyValue] = useState("");
  const [adviseThisPartyCheckBox, setAdviseThisPartyCheckBox] = useState(false);
  const [validatePartyCheckBox, setValidatePartyCheckBox] = useState(false);
  const advancedSearchPartyInputRef = useRef<HTMLInputElement>(null);
  const partySearchButtonRef = useRef<HTMLButtonElement>(null);
  const advancedSearchAdvisePartyInputRef = useRef<HTMLInputElement>(null);
  const advancedSearchAdvisePartyRef = useRef<HTMLDivElement>(null);
  const advisedByRegularSearchResultsRef = useRef<HTMLDivElement>(null);
  const advisedByAdvancedSearchResultsRef = useRef<HTMLDivElement>(null);
  const advisedBySearchButtonRef = useRef<HTMLButtonElement>(null);
  const [
    regularLawyerSearchResultsVisible,
    setRegularLawyerSearchResultsVisible,
  ] = useState(false);
  const [advancedLawyerSearchVisible, setAdvancedLawyerSearchVisible] =
    useState(false);
  const [disableAdviseInput, setDisableAdviseInput] = useState(false);
  const [partySearchError, setPartySearchError] = useState("");
  const [advisePartyError, setAdvisePartyError] = useState("");
  const [validatePartyError, setValidatePartyError] = useState("");
  const [selectedParty, setSelectedParty] = useState<any>();
  const [advisedBySearchValue, setAdvisedBySearchValue] = useState("");
  const [locked, setLocked] = useState(dataPartyForForm);
  const formValue = useSelector(selectPartyForm);
  const dispatch = useDispatch();
  const { actType, publicId } = useParams();
  const regularListRef = useRef<HTMLUListElement>(null);
  const advancedListRef = useRef<HTMLUListElement>(null);
  const [seacrhCriterias, setSearchCriterias] = useState<any>();
  const [maxPageReached, setMaxPageReached] = useState(false);

  let initialLawyerData = useSelector(selectPageData)?.lawyerData || [];
  const metadata = useSelector(selectUser);
  // Simulating the editor's lawyer id, will be changed when BE is ready
  // Added the 999003 value so whenever we work with skip login it does not break
  const currentlyLoggedLawyer = metadata;

  const data = useSelector(selectPageData);
  const page = useSelector(selectPage);

  useEffect(() => {
    if (!data) {
      setDisableAdviseInput(false);
    }
    if (data?.advancedSearchResults) {
      setDataForLawyers(data.advancedSearchResults);
      setAdvancedLawyerSearchVisible(true);
      setRegularLawyerSearchResultsVisible(false);
      setDisableAdviseInput(false);
      setPageData({ advancedSearchResults: null });
      setSearchCriterias(data.searchCriterias);
      dispatch(setPage(0));
      setLoading(false);
      setSearchLoading(false);
      dispatch(setModalData(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (page > 0) {
      dispatch(setPage(0));
      return;
    }

    handleSearchCriteria(advisedBySearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisedBySearchValue]);

  useEffect(() => {
    handleSearchCriteria(advisedBySearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resetSearch = () => {
    setDataForLawyers([]);
    setMaxPageReached(false);
    setSearchCriterias(null);
    setRegularLawyerSearchResultsVisible(false);
    setAdvancedLawyerSearchVisible(false);
    dispatch(setPage(0));
  };

  const handlePartySelection = (result: any) => {
    setSelectedParty(result);

    const birthDate = result?.metaDataValues?.find(
      (metaData: any) => metaData.code === "birthdate",
    )?.value;
    let birthDateFormatted = "";
    if (birthDate) {
      const [year, month, day] = birthDate.split("/");
      birthDateFormatted = `${month}/${day}/${year}`;
    }
    const formatedPhone = result.mainPhoneNumber.slice(-9);
    const phoneCode = result.mainPhoneNumber.slice(0, -9);
    const updatedValues = {
      firstName: result.firstname,
      lastName: result.lastname,
      email: result.email,
      phone: formatedPhone,
      phoneCode: phoneCode,
      birthDate: birthDateFormatted,
      companyName: result.companyName,
      siren: result.siren,
    };

    setSimpleSearchPartyVisible(false);
    setSimpleSearchPartyValue(`${result.firstname} ${result.lastname}`);
    setDataPartyForForm(true);
    setLocked(true);
    setDataForParty([]);
    setAdvisePartyError("");
    setValidatePartyError("");

    setInitialFormValues(updatedValues);
  };

  const setInitialFormValues = (updatedValues: any) => {
    setPopulatedData({
      firstName: updatedValues.firstName,
      lastName: updatedValues.lastName,
      email: updatedValues.email,
      birthDate: updatedValues.birthDate,
      phone: updatedValues.phone,
      countryCode: updatedValues.phoneCode || selectedCountryCode,
      sirenNumber: updatedValues.siren,
      socialReason: updatedValues.companyName,
      advisedBy: "",
      advisedByName: "",
      advisedBySurname: "",
      advisedByEmail: "",
      advisedByPhone: "",
      toBeSealedBy: "",
      advisePartyCheckboxStatus: false,
      validatePartyCheckboxStatus: false,
      signatureInPresence: false,
      adviseLawyerId: "",
      validateLawyerId: "",
    });
  };

  const getInitialFormValue = () => {
    return (
      populatedData || {
        firstName: "",
        lastName: "",
        email: "",
        birthDate: "",
        phone: "",
        countryCode: "" || selectedCountryCode,
        sirenNumber: "",
        socialReason: "",
        advisedBy: "",
        advisedByName: "",
        advisedBySurname: "",
        advisedByEmail: "",
        advisedByPhone: "",
        toBeSealedBy: "",
        advisePartyCheckboxStatus: false,
        validatePartyCheckboxStatus: false,
        signatureInPresence: false,
        adviseLawyerId: "",
        validateLawyerId: "",
      }
    );
  };

  const PhysicalPersonSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(formatLabel(labels.requiredField, labels.name))
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.name)),
    lastName: Yup.string()
      .required(formatLabel(labels.requiredField, labels.surname))
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.surname)),
    email: Yup.string()
      .email(formatLabel(labels.compliantField, labels.email))
      .required(formatLabel(labels.requiredField, labels.email)),
    birthDate: Yup.string()
      .required(formatLabel(labels.requiredField, labels.birthDate))
      .test(
        "is-past-date",
        labels.birthDateMustBeBeforeCurrentDate,
        isPastDate,
      ),
    phone: Yup.string()
      .matches(
        frenchPhoneNumberRegex,
        formatLabel(labels.compliantField, labels.phone),
      )
      .required(formatLabel(labels.requiredField, labels.phone)),
  });

  const CompanySchema = Yup.object().shape({
    socialReason: Yup.string().required(
      formatLabel(labels.requiredField, labels.socialReason),
    ),
    firstName: Yup.string()
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .matches(nameRegex, formatLabel(labels.requiredField, labels.legalName))
      .required(formatLabel(labels.requiredField, labels.legalName)),
    lastName: Yup.string()
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .matches(
        nameRegex,
        formatLabel(labels.requiredField, labels.legalSurname),
      )
      .required(formatLabel(labels.requiredField, labels.legalSurname)),
    phone: Yup.string()
      .matches(
        frenchPhoneNumberRegex,
        formatLabel(labels.compliantField, labels.phone),
      )
      .required(formatLabel(labels.requiredField, labels.phone)),
    email: Yup.string()
      .email(formatLabel(labels.compliantField, labels.email))
      .required(formatLabel(labels.requiredField, labels.email)),
  });

  const getValidationSchema = () =>
    formValue === "TYPE_NATURAL" ? PhysicalPersonSchema : CompanySchema;
  const iconColorAdviseThisParty = adviseThisPartyCheckBox
    ? "#D9D9D9"
    : "black";

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !advancedSearchPartyInputRef.current?.contains(event.target as Node) &&
        partySearchButtonRef.current !== event.target &&
        simpleSearchPartyValue.length === 0
      ) {
        setPartySearchError("");
      }

      if (
        !advisedByAdvancedSearchResultsRef.current?.contains(
          event.target as Node,
        )
      ) {
        setAdvancedLawyerSearchVisible(false);
      }

      if (
        !advisedByRegularSearchResultsRef.current?.contains(
          event.target as Node,
        ) &&
        !advisedBySearchButtonRef.current?.contains(event.target as Node)
      ) {
        setRegularLawyerSearchResultsVisible(false);
        setAdvancedLawyerSearchVisible(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    advancedSearchAdvisePartyInputRef,
    advancedSearchPartyInputRef,
    simpleSearchPartyValue,
  ]);

  const customLawyerValidation = (
    advisePartyField: any,
    fieldName: string,
    value: string,
  ) => {
    if (!adviseThisPartyCheckBox) {
      if (value.length < 3) {
        return formatLabel(labels.minCharLabel, "3");
      }
    }

    if (advisePartyField !== undefined && advisePartyField.value !== "") {
      return;
    }

    const trimmedAdviseParty = advisePartyField.value.trim();

    if (!adviseThisPartyCheckBox && trimmedAdviseParty === "") {
      return formatLabel(labels.requiredField, fieldName);
    }
  };

  const handleSelectionChange = (formik: any) => {
    setPopulatedData(null);
    formik.resetForm();
    setSimpleSearchPartyValue("");
    setFiles([]);
    setAdviseThisPartyCheckBox(false);
    setDisableAdviseInput(false);
    setValidatePartyCheckBox(false);
    setDataPartyForForm(false);
    setLocked(false);
  };

  const handleResetForm = (formik: any) => {
    setPopulatedData(null);
    formik.handleReset();
    setAdviseThisPartyCheckBox(false);
    setDisableAdviseInput(false);
    setValidatePartyCheckBox(false);
    setSelectedCountryCode("+33");
    setFiles([]);
    setSimpleSearchPartyValue("");
    setUploadFileControl("hidden");
    setDataPartyForForm(false);
    setLocked(false);
  };

  // Detect if the user has scrolled to the bottom of the list of lawyers
  useEffect(() => {
    const element = regularLawyerSearchResultsVisible
      ? regularListRef
      : advancedListRef;

    const { current } = element;

    const handleScroll = () => {
      if (current) {
        const atBottom =
          current.scrollHeight - Math.ceil(current.scrollTop) ===
          current.clientHeight;

        if (atBottom && !maxPageReached) {
          dispatch(setPage(page + 1));
          setLoading(true);
        }

        if (page === 0 && dataForLawyers.length > 0) {
          setLoading(false);
        }
      }
    };

    if (element.current) {
      element.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    dataForLawyers.length,
    regularLawyerSearchResultsVisible,
  ]);

  // Handles onChange of the Formik Field - when the user types in the input field
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any,
    fieldName: string,
  ) => {
    const { value } = e.target;

    formik.setFieldValue(fieldName, value);
  };

  const handleSearchCriteria = (searchValue: string) => {
    if (searchLoading) {
      return;
    }
    if (maxPageReached) {
      setLoading(false);
      return;
    }

    if (!seacrhCriterias && searchValue.trim() === "") {
      return;
    }

    setSearchLoading(true);
    const searchTerm = searchValue.toLowerCase();

    const terms = seacrhCriterias
      ? {
          ...seacrhCriterias,
          page: page,
          size: 5,
        }
      : {
          term: searchTerm,
          page: page,
          size: 5,
        };

    if (searchTerm.length >= 3 || seacrhCriterias) {
      axios
        .post(`/api/v1/lawyers/search`, terms)
        .then((response) => {
          if (response.data.page > 0 && response.data.total === 0) {
            setMaxPageReached(true);
            setSearchLoading(false);
            setLoading(false);
            return;
          }

          if (response.data.lawyers.length === 0) {
            setSearchLoading(false);
            setLoading(false);
            return;
          }

          setDataForLawyers([...dataForLawyers, ...response.data.lawyers]);
          setSearchLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            axiosErrorMessages[error.message] || error.message;

          setSearchLoading(false);
          setLoading(false);
          toast.error(errorMessage, toastOptionsError);
        });
    }
  };

  // Function to set the formik values when a lawyer is selected from the dropdown
  const handleFieldValueChange = (
    formik: any,
    result: Lawyers,
    field: string,
  ) => {
    formik.setFieldValue(field, `${result.firstName} ${result.lastName}`);
    if (field === "advisedBy") {
      formik.setFieldValue("adviseLawyerId", result.codeCNBF);
      formik.setFieldValue("advisedByEmail", result.email);
      formik.setFieldValue("advisedByPhone", result.mobileNumber);
      formik.setFieldValue("advisedByName", result.firstName);
      formik.setFieldValue("advisedBySurname", result.lastName);
      setAdvisePartyError("");
    }
  };

  let dropdownOptions = signatoriesPartyTypeList;
  if (actType === "divorce") {
    dropdownOptions = signatoriesLawyerDivorceTypeList;
  } else {
    dropdownOptions = signatoriesPartyTypeList;
  }

  const handleAddMyselfAsLawyer = (field: string) => {
    const currentlyLogged = initialLawyerData.find(
      (lawyer: Lawyers) => lawyer.username === currentlyLoggedLawyer.firstName,
    );

    if (currentlyLogged) {
      if (field === "advisedBy") {
        return adviseThisPartyCheckBox
          ? [
              {
                id: currentlyLogged.id,
                name: `${currentlyLogged.name} ${currentlyLogged.surname}`,
              },
            ]
          : [];
      }
    }

    return [];
  };

  const reinitializeForm = () => {
    setFiles([]);
    setAdviseThisPartyCheckBox(false);
    setDisableAdviseInput(false);
    setValidatePartyCheckBox(false);
    setShowAddParties(false);
  };

  const searchLawyerState = adviseThisPartyCheckBox
    ? "pointer-events-none text-ea-gray-200 border-ea-gray-200"
    : "";
  const sealByCheckBoxState = adviseThisPartyCheckBox
    ? "pointer-events-none disabled"
    : "";
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  const handleSearchPartyInputChange = (e: any) => {
    e.preventDefault();
    setSimpleSearchPartyValue(e.target.value);
    if (e.target.value.length < 3) {
      setPartySearchError(formatLabel(labels.minCharLabel, "3"));
    } else {
      setPartySearchError("");
    }
  };

  const handleSearchPartyCriteria = () => {
    if (simpleSearchPartyValue.length === 0) {
      setPartySearchError(formatLabel(labels.minCharLabel, "3"));
      return;
    }

    setSearchLoading(true);
    const searchTerm = simpleSearchPartyValue.toLowerCase();
    const typeValue = formValue === "TYPE_NATURAL" ? "natural" : "legal";

    if (searchTerm.length >= 3) {
      getParty(searchTerm, typeValue, setDataForParty, setSearchLoading);

      setSimpleSearchPartyVisible(true);
    }
  };

  const resetInitialPopulatedData = (formik: any) => {
    if (populatedData) {
      formik.setFieldValue("firstName", populatedData.firstName);
      formik.setFieldValue("lastName", populatedData.lastName);
      formik.setFieldValue("birthDate", populatedData.birthDate);
      formik.setFieldValue("socialReason", populatedData.socialReason);
      formik.setFieldValue("sirenNumber", populatedData.sirenNumber);
    }
  };

  const handleUploadError = (error: Record<string, any>) => {
    const errorStatus = error.response?.status;
    const errorData = error.response?.data;

    let errorMessage = error.message;

    if (400 === errorStatus) {
      if (errorData && errorData.startsWith("File duplication forbidden")) {
        errorMessage = labels.fileDuplicationForbidden;
      }
    } else {
      errorMessage = axiosErrorMessages[errorMessage];
    }

    toast.error(errorMessage, toastOptionsError);
  };

  return (
    <Formik
      initialValues={getInitialFormValue()}
      validationSchema={getValidationSchema()}
      enableReinitialize
      onSubmit={(values: Values, { resetForm }: FormikHelpers<Values>) => {
        if (actType !== "convention") {
          if (files.length === 0) {
            setUploadFileControl("block");
            return;
          } else if (files.length !== 0) {
            setUploadFileControl("hidden");
          }

          if (validatePartyError.length > 0 && advisePartyError.length > 0) {
            dispatch(setValidatedData(false));
            return;
          }

          // If the user has typed something, but has not selecetd an existing lawyer, show an error
          if (
            values.advisedBy !== "" &&
            values.adviseLawyerId === "" &&
            !adviseThisPartyCheckBox
          ) {
            setAdvisePartyError(
              `${labels.invalidLawyer} ${labels.pleaseSelectLawyer}`,
            );
            return;
          }

          if (values.validateLawyerId === "" && values.toBeSealedBy !== "") {
            setValidatePartyError(
              `${labels.invalidLawyer} ${labels.pleaseSelectLawyer}`,
            );
            return;
          }

          const toBeSealedByMessageField = validatePartyCheckBox
            ? labels.validateParty
            : labels.sealedBy;
          const toBeSealedByField = validatePartyCheckBox
            ? handleAddMyselfAsLawyer("toBeSealedBy")
            : [{ id: values.validateLawyerId, name: values.toBeSealedBy }];
          const advisedByMessageField = adviseThisPartyCheckBox
            ? labels.advisingTheParty
            : labels.advisedBy;
          const advisedByField = adviseThisPartyCheckBox
            ? handleAddMyselfAsLawyer("advisedBy")
            : [{ id: values.adviseLawyerId, name: values.advisedBy }];

          const updatedValues = {
            type: formValue === "TYPE_NATURAL" ? "person" : "company",
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            status: "notSealed",
            companyName:
              formValue !== "TYPE_NATURAL" ? values.socialReason : null,
            image: "",
            birthDate:
              "" !== values.birthDate
                ? formatDateBirth(new Date(values.birthDate))
                : "",
            advisedBy: advisedByField,
            advisedByMessage: advisedByMessageField,
            toBeSealedBy: toBeSealedByField,
            toBeSealedMessage: toBeSealedByMessageField,
            phoneCode: selectedCountryCode,
            // This is done, only for the purpose of frontend working without BE, will be removed when BE is ready
            IDdocument: files,
            adviseLawyerId: values.adviseLawyerId,
            validateLawyerId: values.validateLawyerId,
            inPresence: values.signatureInPresence,
          };

          // Make a POST request if an ID is present
          if (
            adviseThisPartyCheckBox ||
            (validatePartyCheckBox && updatedValues.adviseLawyerId !== "") ||
            updatedValues.adviseLawyerId !== ""
          ) {
            if (publicId) {
              setLoading(true);
              let lawyerId = "";
              let sealingLawyerId = "";
              if (adviseThisPartyCheckBox) {
                // If the advise this party checkbox is checked, the sealing actor is the currently logged lawyer, basically the creator
                lawyerId = currentlyLoggedLawyer.numCNBF;
                sealingLawyerId = currentlyLoggedLawyer.numCNBF;
              } else if (validatePartyCheckBox && !adviseThisPartyCheckBox) {
                // If the advisor comes from the search, but the sealing checkbox is checked, the sealing lawyer is the same as the currently logged lawyer
                sealingLawyerId = currentlyLoggedLawyer.numCNBF;
                lawyerId = values.adviseLawyerId;
              } else {
                // If none of the checkboxes are checked, the sealing lawyer is the same as the advising lawyer
                sealingLawyerId = values.adviseLawyerId;
                lawyerId = values.adviseLawyerId;
              }
              if (lawyerId !== currentlyLoggedLawyer.numCNBF) {
                axios
                  .post(`/api/v1/signbooks/${publicId}/lawyers`, {
                    codeCNBF: lawyerId,
                    firstName: values.advisedByName,
                    lastName: values.advisedBySurname,
                    email: values.advisedByEmail,
                    mobileNumber: values.advisedByPhone,
                  })
                  .then((response) => {})
                  .catch((error) => {
                    const errorMessage =
                      axiosErrorMessages[error.message] || error.message;

                    setLoading(false);

                    toast.error(errorMessage, toastOptionsError);
                  });
              }

              const presenceState =
                actType === "divorce" || updatedValues.inPresence;

              if (!dataPartyForForm) {
                // Check if the party from search or not, if not it enter below if not goes to the POST for imported party
                const personData = {
                  personType: updatedValues.type,
                  firstName: updatedValues.firstName,
                  companyName:
                    formValue === "TYPE_LEGAL" && updatedValues.companyName,
                  siren: formValue === "TYPE_LEGAL" && values.sirenNumber,
                  lastName: updatedValues.lastName,
                  birthDate: updatedValues.birthDate,
                  email: updatedValues.email,
                  mobileNumber: `${updatedValues.phoneCode}${updatedValues.phone}`,
                  advisor: lawyerId,
                  linkedTo: sealingLawyerId,
                  inPresence: presenceState,
                };

                formData.append(
                  "signbookParty",
                  new Blob([JSON.stringify(personData)], {
                    type: "application/json",
                  }),
                );

                axios
                  .post(`/api/v2/signbooks/${publicId}/signatories`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(() => {
                    dispatch(setPostState(true));
                    resetForm();
                    reinitializeForm();
                    setLoading(false);
                    toast.success(
                      labels.documentSuccesfullyUpdated,
                      toastOptions,
                    );
                    resetSearch();
                  })
                  .catch((error) => {
                    handleUploadError(error);

                    setLoading(false);
                  });
              } else {
                // Get the public ID from redux to be changed when it is in the link
                const partyPublicId = selectedParty.publicId;

                const dataToPost = {
                  code: partyPublicId,
                  firstName: updatedValues.firstName,
                  lastName: updatedValues.lastName,
                  birthDate: updatedValues.birthDate,
                  email: updatedValues.email,
                  mobileNumber: `${updatedValues.phoneCode}${updatedValues.phone}`,
                  companyName:
                    formValue === "TYPE_LEGAL" && updatedValues.companyName,
                  siren: formValue === "TYPE_LEGAL" && values.sirenNumber,
                  advisor: lawyerId,
                  linkedTo: sealingLawyerId,
                  inPresence: presenceState,
                };

                formData.append(
                  "importActor",
                  new Blob([JSON.stringify(dataToPost)], {
                    type: "application/json",
                  }),
                );

                axios
                  .post(
                    `/api/v1/signbooks/${publicId}/imported-parties`,
                    formData,
                  )
                  .then((response) => {
                    dispatch(setPostState(true));
                    resetForm();
                    reinitializeForm();
                    setLoading(false);
                    toast.success(
                      labels.documentSuccesfullyUpdated,
                      toastOptions,
                    );
                    resetSearch();
                  })
                  .catch((error) => {
                    handleUploadError(error);

                    setLoading(false);
                  });
              }
            }
          }
        } else {
          // This is only for the convention act type

          const updatedValues = {
            type: formValue === "TYPE_NATURAL" ? "TYPE_NATURAL" : "TYPE_LEGAL",
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            status: "notSealed",
            image: "",
            birthDate:
              "" !== values.birthDate
                ? formatDateBirth(new Date(values.birthDate))
                : "",
            phoneCode: selectedCountryCode,
            // This is done, only for the purpose of frontend working without BE, will be removed when BE is ready
            IDdocument: files[0] ? files[0].name : "",
            adviseLawyerId: values.adviseLawyerId,
            validateLawyerId: values.validateLawyerId,
            inPresence: values.signatureInPresence,
          };

          const personTypeCheck = (typeCheck: string) => {
            switch (typeCheck) {
              case "TYPE_NATURAL":
                return "person";
              case "TYPE_LEGAL":
                return "company";
              default:
                return "person";
            }
          };

          axios
            .post(`/api/v1/signbooks/${publicId}/signatories`, {
              personType: personTypeCheck(updatedValues.type),
              firstName: updatedValues.firstName,
              lastName: updatedValues.lastName,
              birthDate: updatedValues.birthDate,
              email: updatedValues.email,
              mobileNumber: `${updatedValues.phoneCode}${updatedValues.phone}`,
              advisor: currentlyLoggedLawyer.numCNBF,
              linkedTo: currentlyLoggedLawyer.numCNBF,
              inPresence: updatedValues.inPresence,
            })
            .then((response) => {
              dispatch(setPostState(true));

              resetForm();
              reinitializeForm();

              setLoading(false);

              toast.success(labels.documentSuccessfullyAdded, toastOptions);
            })
            .catch((error) => {
              handleUploadError(error);

              setLoading(false);
            });
        }
      }}
    >
      {(formik) => (
        <Form className="border rounded-[4px] border-[#B3B3B3] p-4">
          <h2 className="body-sm">{labels.addOrSearchForParty}</h2>
          <div>
            <div className="flex md:flex-row mt-4 justify-between gap-6 mb-[52px] w-[285px]">
              <FieldDropdown
                options={dropdownOptions}
                width="w-full md:min-w-[285px]"
                initialValue={dropdownOptions[0].value}
                onChange={() => handleSelectionChange(formik)}
              />
            </div>
            <div className="flex">
              <div className="flex relative mb-6 md:mb-0 w-[285px] h-[32px]">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <input
                  type="text"
                  className="flex pl-[47px] pr-[10px] py-2 w-full md:min-w-[285px] text-xs bg-white rounded border border-solid border-neutral-300 text-black"
                  placeholder={labels.titleSearch}
                  value={simpleSearchPartyValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const inputEvent = e.nativeEvent as InputEvent;
                    handleSearchPartyInputChange(e);
                    if (inputEvent.inputType === "deleteContentBackward") {
                      setSimpleSearchPartyVisible(false);
                    }
                  }}
                />
                {simpleSearchPartyVisible && (
                  <div className="absolute top-[100%] left-0 w-[285px] bg-white shadow-md shadow-black/25 rounded mt-1 z-10">
                    <ul className="max-h-[150px] w-full overflow-auto">
                      {!searchLoading ? (
                        simpleSearchPartyValue?.length === 0 && (
                          <li className="flex items-center justify-between py-2 px-[30px]">
                            <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                              {labels.noResultsFound}
                            </p>
                          </li>
                        )
                      ) : (
                        <Loader
                          style={{
                            height: "30px",
                          }}
                        />
                      )}
                      {dataForParty?.map((result: any, index: number) => (
                        <li
                          key={index}
                          className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                          onClick={() => {
                            handlePartySelection(result);
                          }}
                        >
                          <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                            {result.firstname} {result.lastname}
                          </p>
                          <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                            {result.email}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {partySearchError.length > 0 && (
                  <span className="text-[10px] max-w-[293px] text-ea-red absolute bottom-[-20px]">
                    {partySearchError}
                  </span>
                )}
              </div>
              <button
                ref={partySearchButtonRef}
                className="btn-secondary flex border items-center text-center px-[9px] py-[6px] rounded-lg h-[34px] w-[90px] body-lg ml-[25px]"
                type="button"
                onClick={() => {
                  handleSearchPartyCriteria();
                }}
              >
                {labels.search}
              </button>
            </div>
          </div>
          <PartyForm
            formValue={formValue}
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
            files={files}
            setFiles={setFiles}
            uploadFileControl={uploadFileControl}
            formik={formik}
            locked={locked}
          />
          {dataPartyForForm && (
            <div className="flex items-center justify-between bg-ea-lightblue px-2 py-3 rounded-[4px] mt-3">
              <p className="body-md font-semibold">
                {labels.importedPartyFromSearchLabel}
              </p>
              <div className="flex justify-between items-center">
                <Button
                  className={`rounded-[8px] body-md px-1 py-2 ${!locked ? "btn-disabled-light border-ea-gray-100" : "text-black bg-white border border-ea-darkblue"}`}
                  onClick={() => {
                    setLocked(false);
                  }}
                  disabled={!locked}
                >
                  {labels.modificationParty}
                </Button>
                {!locked && (
                  <Button
                    className="btn-secondary rounded-[8px] body-md px-1 py-2 ml-3"
                    onClick={() => {
                      setLocked(true);
                      resetInitialPopulatedData(formik);
                    }}
                  >
                    {labels.cancel}
                  </Button>
                )}
              </div>
            </div>
          )}
          {actType !== "convention" && (
            <div className="mt-8">
              <h2 className="body-lg">{labels.lawyer}</h2>
              <p className="body-sm text-ea-gray-300 mt-2">
                {labels.lawyerAddPartyInfo}
              </p>
              <div className="flex-col md:flex md:flex-row justify-between">
                <div className="mt-4 min-w-[285px]">
                  <label className="flex relative body-md text-center items-center custom-checkbox-container">
                    <input
                      checked={adviseThisPartyCheckBox}
                      name="advisePartyCheckBox"
                      type="checkbox"
                      className="custom-checkbox"
                      onChange={() => {
                        // Reset the advisedBy field when the user unchecks the checkbox on the line below
                        // Formik manage the advisedBy field, this way
                        formik.setFieldValue("advisedBy", "");

                        setAdviseThisPartyCheckBox(!adviseThisPartyCheckBox);
                        setDisableAdviseInput(!disableAdviseInput);
                        if (actType !== "digital") {
                          if (adviseThisPartyCheckBox) {
                            setValidatePartyCheckBox(false);
                            formik.setFieldValue(
                              "adviseLawyerId",
                              currentlyLoggedLawyer,
                            );
                          } else {
                            setValidatePartyCheckBox(true);
                          }
                        }
                        setAdvisePartyError("");
                        setValidatePartyError("");
                      }}
                    />
                    {labels.advisingTheParty}
                  </label>
                  <div className="flex relative mt-2">
                    <Icon
                      type="search"
                      color={iconColorAdviseThisParty}
                      className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                    />
                    <Field
                      name="advisedBy"
                      validate={(value: string) => {
                        const error = customLawyerValidation(
                          formik.getFieldProps("advisedBy"),
                          labels.advisingTheParty,
                          value,
                        );
                        setAdvisePartyError(error ? error : "");
                      }}
                      innerRef={advancedSearchAdvisePartyInputRef}
                      type="text"
                      className="flex pl-[47px] pr-[10px] py-2 min-w-[100%] text-xs bg-white rounded border border-solid border-neutral-300 text-black"
                      placeholder={labels.searchLabelForLawyer}
                      disabled={disableAdviseInput}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputEvent = e.nativeEvent as InputEvent;

                        if (
                          inputEvent.inputType === "deleteContentBackward" &&
                          formik.values.adviseLawyerId
                        ) {
                          formik.setFieldValue("advisedBy", "");
                          formik.setFieldValue("adviseLawyerId", "");
                          return;
                        }
                        if (inputEvent.inputType === "deleteContentBackward") {
                          setDataForLawyers([]);
                        }

                        return handleFieldChange(e, formik, "advisedBy");
                      }}
                    />
                    {formik.values.advisedBy.length > 0 && (
                      <div
                        className="cursor-pointer absolute top-[13px] right-[10px]"
                        onClick={() => {
                          formik.setFieldValue("advisedBy", "");

                          resetSearch();
                        }}
                      >
                        <Icon type="close" className="size-[8px]" />
                      </div>
                    )}
                  </div>

                  {/* Regular lawyer search results */}
                  {regularLawyerSearchResultsVisible && (
                    <div
                      className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px] absolute bg-white max-w-[285px] w-full z-20"
                      ref={advisedByRegularSearchResultsRef}
                    >
                      <ul
                        className="max-h-[150px] w-full overflow-auto"
                        ref={regularListRef}
                      >
                        {!searchLoading && dataForLawyers?.length === 0 && (
                          <li className="flex items-center justify-between py-2 px-[30px]">
                            <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                              {labels.noResultsFound}
                            </p>
                          </li>
                        )}

                        {dataForLawyers.map((result: any, index: number) => (
                          <li
                            key={index}
                            className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                            onClick={() => {
                              handleFieldValueChange(
                                formik,
                                result,
                                "advisedBy",
                              );
                              setAdvancedLawyerSearchVisible(false);
                              setRegularLawyerSearchResultsVisible(false);
                            }}
                          >
                            <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                              {result.firstName} {result.lastName}
                            </p>
                            <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                              {result.codeBarreau}
                            </p>
                          </li>
                        ))}

                        {searchLoading && (
                          <Loader
                            style={{
                              height: "30px",
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  )}
                  {/* Advanced lawyer search results */}
                  {advancedLawyerSearchVisible && (
                    <div
                      className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px] absolute bg-white max-w-[285px] w-full z-20"
                      ref={advisedByAdvancedSearchResultsRef}
                    >
                      <ul
                        className="max-h-[150px] w-full overflow-auto"
                        ref={advancedListRef}
                      >
                        {dataForLawyers?.length === 0 && (
                          <li className="flex items-center justify-between py-2 px-[30px]">
                            <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                              {labels.noResultsFound}
                            </p>
                          </li>
                        )}
                        {dataForLawyers.map((result: any, index: number) => (
                          <li
                            key={index}
                            className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                            onClick={() => {
                              handleFieldValueChange(
                                formik,
                                result,
                                "advisedBy",
                              );
                              setAdvancedLawyerSearchVisible(false);
                              setDisableAdviseInput(false);
                            }}
                          >
                            <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                              {result.firstName} {result.lastName}
                            </p>
                            <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                              {result.codeBarreau}
                            </p>
                          </li>
                        ))}
                        {searchLoading && (
                          <Loader
                            style={{
                              height: "30px",
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  )}
                  {advisePartyError.length > 0 && (
                    <span className="text-[10px] max-w-[293px] text-ea-red">
                      {advisePartyError}
                    </span>
                  )}
                </div>
                <button
                  className={`btn-secondary  border items-center text-center px-[9px] py-[6px] rounded-lg h-[34px] w-[90px] body-lg mt-[42px] ml-[16px] ${searchLawyerState}`}
                  type="button"
                  disabled={loading}
                  ref={advisedBySearchButtonRef}
                  onClick={() => {
                    if (advisePartyError.length > 0) {
                      return;
                    }

                    setRegularLawyerSearchResultsVisible(true);
                    if (formik.values.advisedBy === advisedBySearchValue) {
                      return;
                    }
                    setDataForLawyers([]);
                    setAdvisedBySearchValue(formik.values.advisedBy);
                    setMaxPageReached(false);
                    setSearchCriterias(null);
                    dispatch(setPage(0));
                  }}
                >
                  {labels.search}
                </button>
                <div
                  className={`relative flex justify-between h-[34px] text-center items-center mt-[42px] ml-[16px] ${searchLawyerState}`}
                  ref={advancedSearchAdvisePartyRef}
                >
                  <Icon
                    type="filters"
                    className="absolute left-[14px] size-[15px]"
                  />
                  <Button
                    className="body-md pl-11 p-2 rounded-lg block w-[180px] text-left hover:bg-ea-lightblue"
                    type="button"
                    onClick={() => {
                      formik.setFieldValue("advisedBy", "");
                      dispatch(
                        setModalData({
                          searchableField: "advisedBy",
                        }),
                      );
                      resetSearch();
                      dispatch(showModal("advancedSearchLawyer"));
                    }}
                  >
                    {labels.advancedSearchTabLabel}
                  </Button>
                </div>
              </div>
              {actType !== "digital" && (
                <div className="flex-col md:flex md:flex-row justify-between">
                  <div className="mt-4 min-w-[285px]">
                    <label
                      className={`flex relative body-md text-center items-center custom-checkbox-container ${sealByCheckBoxState}`}
                    >
                      <input
                        checked={validatePartyCheckBox}
                        name="validatePartyCheckBox"
                        type="checkbox"
                        className="custom-checkbox"
                        onChange={() => {
                          setValidatePartyCheckBox(!validatePartyCheckBox);
                          if (!validatePartyCheckBox) {
                            formik.setFieldValue(
                              "validateLawyerId",
                              currentlyLoggedLawyer,
                            );
                          }
                          setAdvisePartyError("");
                          setValidatePartyError("");
                        }}
                      />
                      {labels.sealThisParty}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="mt-8 flex w-full justify-between items-center">
            <Button
              onClick={() => handleResetForm(formik)}
              type="reset"
              className="flex items-center"
            >
              <Icon
                type="refresh"
                color="#7F7F7F"
                className="w-[16px] h-[16px] mr-[16px]"
              />
              <span className="body-md text-slate-500">{labels.resetForm}</span>
            </Button>
            <Button
              type="submit"
              className="btn btn-primary rounded-lg py-2 px-4 body-md flex items-center justify-center"
              disabled={loading}
            >
              {loading && <Spinner className="mr-2 size-4" />}
              {labels.addThisParty}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchOrCreateParties;
