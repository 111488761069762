import { useEffect } from "react";

import PlainTable from "@components/tables/PlainTable";

import Button from "@ui/Button";
import Pagination from "@ui/Pagination";

import { labels } from "@constants";

import { ActHistoryTableProps } from "@types";
import { ActHistoryProps } from "@types";

import { exportPdf } from "@utils";

const ActHistory = ({ rows, maxPage, setPage, title }: ActHistoryProps) => {
  const columns = [
    {
      label: labels.date,
      render: (data: ActHistoryTableProps) => <p>{data.date}</p>,
      className: "body-md py-4",
    },
    {
      label: labels.details,
      render: (data: ActHistoryTableProps) => (
        <p className="line-clamp-1 pr-6">{data.details}</p>
      ),
      className: "body-md py-4",
    },
    {
      label: labels.partiesOrLawyer,
      render: (data: ActHistoryTableProps) => <p>{data.signatory}</p>,
      className: "body-md py-4",
    },
  ];

  const scrollToElement = () => {
    const element = document.getElementById("act-history");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (window.location.hash === "#act-history") {
      scrollToElement();
    }
  }, []);

  const marginBottomClass = maxPage > 0 ? "mb-[40px]" : "mb-0";

  return (
    <div className="headline-md mx-[6px] md:mx-6 lg:auto text-black mt-16 mb-16 block">
      <section className="w-full lg:w-[612px] mx-auto" id="act-history">
        <div
          className={`flex justify-between items-center pr-[9px] ${marginBottomClass}`}
        >
          <h2 className="flex">{labels.ActHistoricTitle}</h2>
          <div className="flex justify-between hidden md:block">
            <Button
              type="button"
              className="btn-secondary h-[34px] flex border items-center rounded-lg pl-3 pr-3 pb-1 body-lg"
              onClick={() => {
                exportPdf(title);
              }}
            >
              {labels.printHistoryButton}
            </Button>
          </div>
        </div>
        {maxPage > 0 && (
          <div className="relative w-full h-[25px] block z-50">
            <Pagination
              totalPages={maxPage}
              setPage={setPage}
              positionClass={"right-0"}
            />
          </div>
        )}
        <div className="mt-8 pb-7 w-full">
          <PlainTable columns={columns} rows={rows} tableType="actHistory" />
        </div>
      </section>
    </div>
  );
};

export default ActHistory;
