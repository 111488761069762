import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import HistoryFilters from "@components/filters/HistoryFilters";
import PlainTable from "@components/tables/PlainTable";

import Icon from "@ui/Icon";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { hideModal } from "@reducers/modalsSlice";

import { HistoryFilterProps, InvoiceHistoryTableProps } from "@types";

// Hardcoded 10 rows of dummy data for the table.
// We'll update this when there's a backend to fetch the data from.
const tableCells: InvoiceHistoryTableProps[] = Array.from({ length: 10 }).map(
  () => ({
    date: "11/11/25 11:14",
    details: "facture f25120789", // Update the type of 'details' property
    document: "e-acte Maffre", // Add the missing 'document' property
    amount: "30,00 €",
    action: (
      <div className="text-center">
        <button>
          <Icon type="download" className="size-4" />
        </button>
      </div>
    ),
  }),
);

const InvoiceHistory = () => {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const tableHeaders = [
    {
      label: labels.date,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>{tableCells.date}</p>
      ),
      className: "body-md py-4",
    },
    {
      label: labels.details,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <div className="flex items-center">
          <img className="mr-4" src="assets/pdf-icon.png" alt="" />
          {tableCells.details}
        </div>
      ),
      className: "body-md py-4",
    },
    {
      label: labels.document,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>
          <Link
            to="/acts/birth/act-details/20240712141212-Y47oxqiX8WdUK44kr"
            className="underline underline-offset-2"
            onClick={() => {
              dispatch(hideModal());
            }}
          >
            {tableCells.document}
          </Link>
        </p>
      ),
      className: "body-md py-4 ml-[-8px]",
    },
    {
      label: labels.amount,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>{tableCells.amount}</p>
      ),
      className: "body-md py-4",
    },
    {
      label: "",
      render: (tableCells: InvoiceHistoryTableProps) => (
        <div>{tableCells.action}</div>
      ),
      className: "body-md py-4",
    },
  ];

  // Might be used when backend is implemented
  // eslint-disable-next-line
  const [filters, setFilters] = useState<HistoryFilterProps>({
    selected: [],
    startDate: "",
    endDate: "",
  });

  return (
    <Modal title={labels.invoicesHistory} width={720}>
      <div className="flex justify-between w-full my-[18px] pr-4">
        <div className="flex items-center">
          <div className="relative">
            <Icon
              type="search"
              color="black"
              className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
            />
            <input
              type="text"
              className="flex pl-[47px] pr-[10px] py-2 min-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
              placeholder={labels.titleSearch}
            />
          </div>
          <button
            type="button"
            className="flex items-center my-auto text-sm ml-4"
            onClick={(e) => {
              e.preventDefault();
              setShowFilters(!showFilters);
            }}
          >
            <Icon
              type="filters"
              color="#000"
              className="w-[18px] h-[18px] mr-[12px]"
            />
            {labels.filters}
          </button>
        </div>
        <button className="btn-secondary px-5 py-1 text-sm rounded-lg">
          {labels.export}
        </button>
      </div>
      {showFilters && (
        <HistoryFilters
          setShowFilters={setShowFilters}
          setFilters={setFilters}
        />
      )}
      <PlainTable
        columns={tableHeaders}
        rows={tableCells}
        tableType="invoiceHistory"
      />
    </Modal>
  );
};

export default InvoiceHistory;
