import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "@components/layout/Layout";

import ButtonDropdown from "@ui/ButtonDropdown";
import Icon from "@ui/Icon";

import { labels } from "@constants";
import { signatoryDocumentsValidation } from "@constants";

import { selectModalData } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";
import {
  selectValidatedDocuments,
  setValidatedDocuments,
} from "@reducers/validationSlice";

import { addQueryParams } from "@utils";

const DocumentValidation = () => {
  // Will be used when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { signBookNumber, actorCode } = useParams();
  // Will be used when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState(signatoryDocumentsValidation);
  // Will be used when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectModalData);
  const validatedDocuments = useSelector(selectValidatedDocuments);

  useEffect(() => {
    if (data) {
      return;
    }
    dispatch(showModal("restrictedAccess"));
  }, [dispatch, data]);

  const isDocumentValidated = (id: string) => validatedDocuments?.includes(id);

  // Validate only the document which documentid is passed
  const validateFile = (documentId: string) => {
    dispatch(setValidatedDocuments([...validatedDocuments, documentId]));
  };

  return (
    <Layout
      backgroundColor="bg-[#F9F9F9]"
      onboarding={true}
      helpButton={false}
      logoLink={false}
    >
      <div className="flex items-center justify-center">
        <div className="bg-white lg:w-[695px] mx-6 lg:mx-auto rounded-t-lg pb-2 mb-16 my-8 p-[40px] max-w-[579px]">
          <h1 className="headline-lg mb-5">{labels.forValidatingTabLabel}</h1>

          <div className="mb-12">
            <p className="body-sm">
              {labels.receivedDocumentForValidationLabel}
            </p>
            <p className="body-sm">{labels.readTheDocumentsAndValidate}</p>
          </div>

          <div>
            {files.map((file, index) => {
              const validated = isDocumentValidated(file.documentId);

              const buttonOptions = [
                {
                  label: labels.view,
                  link: addQueryParams(
                    `/acts/${file.actType}/new/documents/preview`,
                    {
                      documentId: file.documentId,
                      returnPath: `/document-validation?signBookNumber=1234&actorCode=4321`,
                      actId: file.actId,
                      actTitle: file.actTitle,
                      documentName: file.name,
                      lawyer: "true",
                    },
                  ),
                },
              ];

              // If the document is validated, we don't need the "validate" option.
              if (validated) {
                buttonOptions.shift();
              }

              return (
                <div
                  key={index}
                  className="flex justify-between items-center mt-[24px] first:mt-0 mb-[24px]"
                >
                  <span className="flex w-full items-center py-[5px]">
                    <img src="/assets/pdf-icon.png" alt="" />
                    <span className="ml-4 body-sm">{file.name}</span>
                  </span>

                  {validated && (
                    <Icon
                      type="check"
                      color="#00C45A"
                      className="size-[20px] mr-[20px]"
                    />
                  )}

                  <ButtonDropdown
                    options={buttonOptions}
                    disabled={loading}
                    buttonLabel={validated ? labels.view : labels.validate}
                    onClick={() => {
                      // If the document is not validated we validated it on click and return.
                      // Otherwise, we navigate to the preview page.
                      if (!validated) {
                        validateFile(file.documentId);
                        return;
                      }

                      navigate(
                        addQueryParams(
                          `/acts/${file.actType}/new/documents/preview`,
                          {
                            documentId: file.documentId,
                            returnPath: `/document-validation?signBookNumber=1234&actorCode=4321`,
                            actId: file.actId,
                            actTitle: file.actTitle,
                            documentName: file.name,
                            lawyer: "true",
                          },
                        ),
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentValidation;
