import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EntityCard from "@components/fragments/EntityCard";
import SearchOrCreateParties from "@components/fragments/SearchOrCreateParties";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";
import TabButtons from "@ui/TabButtons";
import TabContent from "@ui/TabContent";

import {
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import {
  setLastReachedStep,
  setValidatedData,
} from "@reducers/actCreationSlice";
import {
  selectPostState,
  setModalData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { resetPartyForm } from "@reducers/partyFormSlice";
import { setPage } from "@reducers/tracesSlice";

import { Lawyers, PathMap, SignatoriesParties } from "@types";

import { getPathMap } from "@utils";
import { fetchLawyers, fetchSignatories } from "@utils/api/api";

const PartySignatures = ({
  actions = true,
  compact = false,
}: {
  actions?: boolean;
  compact?: boolean;
}) => {
  const navigate = useNavigate();
  const { actType, publicId } = useParams();
  const user = useSelector(selectUser);

  const [showAddParties, setShowAddParties] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [partyData, setPartyData] = useState<SignatoriesParties[]>([]);
  const [lawyerData, setLawyerData] = useState<Lawyers[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [partyGetFinished, setPartyGetFinished] = useState<boolean>(false);
  const [lawyerGetFinished, setLawyerGetFinished] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [locked, setLocked] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);
  const [actCreator, setActCreator] = useState<string | null>(null);
  const [actInfo, setActInfo] = useState<any>([]);
  const creationState = useSelector(selectPostState);

  const partyTitle =
    "convention" === actType ? labels.clients : labels.signingParties;
  const addPartyButtonTitle =
    "convention" === actType ? labels.addClient : labels.addParty;
  const deletePartyButtonTitle =
    "convention" === actType
      ? labels.deleteClientLabel
      : labels.deletePartyLabel;
  const pathMap: PathMap = getPathMap(actType, publicId);

  const firstStepPath = publicId ? `/${publicId}/information` : pathMap[1].url;
  const thirdStepPath = pathMap[3]?.url || "";

  useEffect(() => {
    if (!publicId) {
      return;
    }

    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setActInfo(response.data);
        setId(response.data.id);
        setActCreator(response.data.actCreatorCNBF);
        // If the act type is different from the one we're on, we redirect to the correct one
        const actTypeDcm = actTypeNameMap[response.data.businessType];
        const normalizedType =
          undefined !== actTypeDcm
            ? actTypeDcm
            : actTypeNameMap[response.data.type];

        if (normalizedType !== actType) {
          navigate(`/acts/${normalizedType}/${publicId}/parties`);
        }
        const responseStep = response.data.metaDataValues.find(
          (item: any) => item.code === "wfStep",
        )?.value;

        dispatch(setLastReachedStep(responseStep));
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [publicId, actType, navigate, dispatch]);

  const partyDataUpdate = (dataOfParties: any[]) => {
    if (dataOfParties) {
      let partyUpdate: SignatoriesParties[] = [];

      // Create a mapping from lawyer codeCNBF to lawyer details
      const lawyerMap = new Map();

      lawyerData.forEach((lawyer: Lawyers) => {
        lawyerMap.set(lawyer.codeCNBF, {
          firstName: lawyer.firstName,
          lastName: lawyer.lastName,
        });
      });

      // Update the partyData with the advisedBy name from lawyerMap
      dataOfParties.forEach((party) => {
        if (party.advisedBy?.[0]?.id && lawyerMap.has(party.advisedBy[0].id)) {
          const lawyerDetails = lawyerMap.get(party.advisedBy[0].id);

          if (party.advisedBy[0].name === "") {
            const newParty = {
              ...party,
              advisedBy: party.advisedBy.map((advisor: any, index: number) =>
                index === 0
                  ? {
                      ...advisor,
                      name: `${lawyerDetails.firstName} ${lawyerDetails.lastName}`,
                    }
                  : advisor,
              ),
            };
            partyUpdate.push(newParty);
          } else {
            partyUpdate.push(party);
          }
        } else {
          partyUpdate.push(party);
        }
      });

      setPartyData(partyUpdate);
    }
  };

  // Add party to advisingPartyName array of the new lawyer
  const lawyerDataUpdate = (dataOfLawyers: any[]) => {
    if (dataOfLawyers) {
      let lawyerUpdate: Lawyers[] = [];

      // Create a mapping from lawyer codeCNBF to advised parties
      const lawyerPartyMap = new Map();

      partyData.forEach((party) => {
        dataOfLawyers.forEach((lawyer) => {
          if (party.advisedBy?.[0]?.id === lawyer.codeCNBF) {
            // Initialize the array if it doesn't exist
            if (!lawyerPartyMap.has(lawyer.codeCNBF)) {
              lawyerPartyMap.set(lawyer.codeCNBF, []);
            }

            // Push the party details into the array
            lawyerPartyMap.get(lawyer.codeCNBF).push({
              id: `${party.id}`,
              name:
                party.type === "TYPE_LEGAL"
                  ? party.companyName
                  : `${party.firstName} ${party.lastName}`,
            });
          }
        });
      });

      // Update the lawyerData with the aggregated advisedPartyName
      lawyerData.forEach((lawyer) => {
        const newLawyer = { ...lawyer };
        newLawyer.advisingPartyName = lawyerPartyMap.get(lawyer.codeCNBF);
        lawyerUpdate.push(newLawyer);
      });

      setLawyerData(lawyerUpdate);
      // dispatch(setPageData({ lawyerData: lawyerUpdate }));
    }
  };

  useEffect(() => {
    if (id === null || user === null) {
      return;
    }

    const initializeData = async () => {
      try {
        const [signatoriesData, lawyersData] = await Promise.all([
          fetchSignatories(publicId, user, labels),
          fetchLawyers(publicId, user, labels),
        ]);
        setInitializing(false);
        setPartyData(signatoriesData);
        setPartyGetFinished(true);
        setLawyerData(lawyersData);
        setLawyerGetFinished(true);
      } catch (error) {
        // Error handling is already managed within the fetch functions
      }
    };
    initializeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creationState, user]);

  useEffect(() => {
    if (partyGetFinished && lawyerGetFinished) {
      partyDataUpdate(partyData);
      lawyerDataUpdate(lawyerData);
      setPartyGetFinished(false);
      setLawyerGetFinished(false);
      dispatch(setPostState(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyGetFinished, lawyerGetFinished]);

  useEffect(() => {
    const extractPartiesForSealing = partyData.filter(
      (party: any) => party.linkedTo === user.numCNBF,
    );

    const allLocked = extractPartiesForSealing.every(
      (extract: any) => extract.isLocked,
    );

    setLocked(allLocked);
  }, [partyData, user.numCNBF]);

  useEffect(() => {
    if (partyData.length === 0) {
      dispatch(setValidatedData(false));
      setLocked(false);
      return;
    }
    if (partyData.length > 0 && actType === "digital") {
      setDisabled(false);
      dispatch(setValidatedData(true));
      return;
    }
    if (!locked && actType !== "convention" && actType !== "digital") {
      setDisabled(false);
      dispatch(setValidatedData(false));
      return;
    }
    dispatch(setValidatedData(true));
  }, [partyData, dispatch, locked, actType]);

  const editPartyCallback = (index: number) => {
    const preparedData = {
      party: partyData[index],
      index: index,
      id: id,
    };

    dispatch(setModalData(preparedData));
    dispatch(showModal("editParty"));
  };

  const editLawyerCallback = (index: number) => {
    const preparedData = {
      lawyerCode: lawyerData[index].codeCNBF || {},
      lawyer: lawyerData[index],
      index: index,
      id: id,
    };

    dispatch(setModalData(preparedData));
    dispatch(showModal("editLawyer"));
  };

  let tabsList =
    "convention" !== actType
      ? [
          {
            title: labels.parties,
            id: 0,
            testId: "parties-tab",
          },
          {
            title: labels.lawyers,
            id: 1,
            testId: "lawyers-tab",
          },
        ]
      : [];

  const handlePartyNotification = (partyId: string) => {
    axios
      .post(
        `/api/v1/signbooks/${publicId}/signatories/${partyId}/notification?type=DOCUMENT_UPLOAD`,
      )
      .then((response) => {
        if (response.status === 204) {
          toast.success(labels.notificationSuccess, toastOptions);
        }
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const notifyParty =
    actInfo.signingStatus === "BEFORE_SIGNING" &&
    (actInfo.step === "created" || actInfo.step === "shared") &&
    actInfo.type !== "numerise"
      ? {
          label:
            actInfo.type !== "econvention"
              ? labels.notifyParty
              : labels.notifyClient,
          onClick: (index: number) => {
            handlePartyNotification(partyData[index].id);
          },
        }
      : "";

  const dropdownOptionsForSignatories = [
    {
      label: labels.modificationParty,
      onClick: editPartyCallback,
    },
    ...(notifyParty ? [notifyParty] : []),
    {
      label: labels.changeLawyer,
      onClick: (index: number) => {
        const preparedData = {
          party: partyData[index],
          lawyerData: lawyerData,
          index: index,
          signBookNumber: publicId,
        };
        dispatch(setModalData(preparedData));
        dispatch(showModal("changeLawyer"));
      },
    },
    {
      label: deletePartyButtonTitle,
      onClick: (index: number) => {
        const preparedData = {
          partyId: partyData[index].id,
          lawyerId: partyData[index].advisor,
          actCreator,
        };
        dispatch(setModalData(preparedData));
        dispatch(showModal("deleteParty"));
      },
    },
  ];

  if (actType === "convention") {
    // Remove the first option
    dropdownOptionsForSignatories.splice(0, 1);
    // Remove the 3rd option
    dropdownOptionsForSignatories.splice(1, 1);
  }
  if (actType === "digital") {
    dropdownOptionsForSignatories.splice(0, 1);
  }

  const dropdownOptionsForLawyers = [
    {
      label: labels.modificationLawyer,
      link: "",
      onClick: editLawyerCallback,
    },
  ];

  const tables = [
    <EntityCard
      parties={partyData}
      setParties={setPartyData}
      dropdownOptions={dropdownOptionsForSignatories}
      buttonLabel=""
      icon=""
      infoMessage={true}
      hasButton={true}
      className="border-none shadow-md shadow-stone-400 rounded-lg px-4 py-2 h-[107px] mt-6"
      onClick={editPartyCallback}
      actInfo={actInfo}
    />,
    <EntityCard
      parties={lawyerData}
      setParties={setLawyerData}
      dropdownOptions={dropdownOptionsForLawyers}
      buttonLabel=""
      icon=""
      infoMessage={true}
      hasButton={true}
      className="border-none shadow-md shadow-stone-400 rounded-lg px-4 py-2 h-[107px] mt-6"
      onClick={editLawyerCallback}
      actInfo={actInfo}
    />,
  ];

  if ("convention" === actType) {
    tables.pop();
  }
  const disabledState = () => {
    if (actType === "divorce") {
      return partyData.length < 2;
    }
    return partyData.length === 0;
  };

  const nextButtonLabel =
    "convention" === actType || "digital" === actType || locked
      ? labels.next
      : labels.lockParty;

  const disabledButtonStyle = disabled ? "btn-disabled-light" : "";

  // Disable button if there are 2 parties and the act type is divorce
  useEffect(() => {
    if ("divorce" === actType && partyData.length === 2) {
      setDisabled(true);
    } else if ("divorce" === actType && partyData.length < 2) {
      dispatch(setValidatedData(false));
      setDisabled(false);
    }
  }, [partyData, actType, dispatch]);

  const paddingClass = compact ? "py-0" : "py-[32px]";
  const scrollClass =
    partyData.length > 3 || lawyerData.length > 3
      ? "overflow-y-auto h-[410px]"
      : "";
  const loadingClass = creationState ? "animate-pulse opacity-10" : "";

  if (initializing) {
    return (
      <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
        <Spinner className="size-10 !border-t-black" />
      </div>
    );
  }

  return (
    <div
      className={`[&_.party-card:hover]:z-50 mx-[6px] md:mx-[45px] ${paddingClass}`}
    >
      <section className="w-full lg:w-[605px] mx-auto ">
        {actions && (
          <div className="flex justify-between mb-[20px]">
            <h2 className="mb-3 headline-lg ">{partyTitle}</h2>
            {!showAddParties ? (
              <Button
                className={`btn-secondary flex items-center px-3 py-1 rounded-lg border h-[34px] body-lg ${disabledButtonStyle}`}
                onClick={() => {
                  setShowAddParties(true);
                  dispatch(resetPartyForm());
                  dispatch(setPage(0));
                }}
                disabled={disabled}
              >
                <Icon type="plus" className="size-[24px]" />
                {addPartyButtonTitle}
              </Button>
            ) : (
              <>
                <button
                  onClick={() => {
                    dispatch(resetPartyForm());
                    setShowAddParties(false);
                  }}
                >
                  <Icon type="close" className="size-[15px] mt-8" />
                </button>
              </>
            )}
          </div>
        )}
        {showAddParties && (
          <SearchOrCreateParties setShowAddParties={setShowAddParties} />
        )}
        <div className="flex flex-col max-md:ml-0 max-md:w-full mb-10">
          <div className="flex flex-col self-stretch my-auto max-sm:mt-0 max-md:mt-10 max-md:max-w-[620px]">
            {/* Tabs */}
            <TabButtons
              tab={activeTab}
              setTab={setActiveTab}
              tabsList={tabsList}
            />
            <TabContent
              activeTab={activeTab}
              data={tables}
              className={`${scrollClass} border-t mt-[5px] px-1 py-2 ${loadingClass}`}
            />
          </div>
        </div>
        {actions && (
          <div className="lg:auto text-black body-lg">
            <section className="w-full lg:w-[605px] mx-auto">
              <div className="flex justify-between items-center">
                <Link
                  to={`/acts/${actType}${firstStepPath}`}
                  className="btn-base btn-secondary"
                >
                  {labels.previous}
                </Link>

                <Button
                  type="button"
                  className="btn-base btn-primary"
                  onClick={() => {
                    if (
                      actType !== "convention" &&
                      actType !== "digital" &&
                      !locked
                    ) {
                      dispatch(showModal("sealMyParties"));
                      return;
                    }

                    if (partyData.length > 0) {
                      navigate(`/acts/${actType}${thirdStepPath}`);
                    }
                  }}
                  disabled={disabledState()}
                  data-testid="next"
                >
                  {nextButtonLabel}
                </Button>
              </div>
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default PartySignatures;
