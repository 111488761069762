import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonDropdown from "@ui/ButtonDropdown";
import Icon from "@ui/Icon";
import ProgressBar from "@ui/ProgressBar";
import Table from "@ui/Table";

import {
  actMapAmount,
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import {
  selectActData,
  selectPageData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { selectSettings, selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import {
  ActsTableProps,
  ButtonDropdownOptions,
  EDocumentsDataTableProps,
} from "@types";

import { addQueryParams, downloadPDF, getActLink } from "@utils";

const ActsTable = (props: EDocumentsDataTableProps) => {
  const [dataForTable, setDataForTable] = useState<ActsTableProps[]>(
    props.data,
  );

  const [duplicating, setDuplicating] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectPageData);
  const user = useSelector(selectUser);
  const actData = useSelector(selectActData);
  const settings = useSelector(selectSettings);

  const returnTabUrl = props.activeTab;

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Extract the day, month, and year from the date object
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    // Return the date in dd/mm/yyyy format
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.actCreated && actData?.id) {
      axios
        .get(`/api/v1/signbooks/${actData?.id}`)
        .then((response) => {
          const createdAct = {
            id: response.data.id.toString(),
            title: response.data.name,
            creationDate: formatDate(response.data.creationDate),
            deadline: formatDate(response.data.expirationDate),
            state: "signé par Gérard Manvussa (6/7)",
            currentStep: 6,
            maxSteps: 7,
            expiresIn: 10,
            documentId: "multiple",
            actType: response.data.type,
            businessSubType: response.data.businessSubType,
            isNew: true,
            wasOpened: response.data.wasOpened,
          };

          setDataForTable([createdAct]);
        })
        .catch((error) => {
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    }

    dispatch(setPageData(null));
    dispatch(setModalData(null));
  }, [data, dispatch, actData?.id]);

  const containsDigital = returnTabUrl?.includes("digital");

  const columns = [
    {
      param: "NAME",
      label: labels.title,
      render: (data: ActsTableProps) => {
        const status = data.status || "";
        const signingStatus = data.signingStatus;
        const actIsNew =
          ["created", "shared"].includes(status) &&
          signingStatus === "BEFORE_SIGNING" &&
          !data.wasOpened;

        const actLink = getActLink(
          {
            type: data.actType || "",
            status: data.status || "",
            signingStatus: data.signingStatus || "",
            publicId: data.id || "",
            subType: data.businessSubType || "",
          },
          returnTabUrl,
        );

        const handleActLink = () => {
          if (data.wasOpened) {
            return;
          }
          axios
            .put(`/api/v1/signbooks/${data.id}`, {
              name: data.title,
              description: data.description,
              expirationDate: data.expirationDateTimestamp,
              type: data.actType,
              mustReadAllDocumentPages: data.mustReadAllDocumentPages,
              wasOpened: true,
            })
            .then(() => {
              navigate(actLink);
            })
            .catch((error) => {
              if (error.response.data.length > 0) {
                error.response.data.forEach((error: any) => {
                  toast.error(error.message, toastOptionsError);
                });
                return;
              }

              toast.error(axiosErrorMessages[error.message], toastOptionsError);
            });
        };

        return (
          <div className="relative inline-block">
            {(data.isNew || actIsNew) && (
              <span className="px-[15px] py-[1px] body-md bg-ea-red absolute text-white rounded-[11px] left-full top-[-8px] translate-y-[calc(-45%)]">
                {labels.new}
              </span>
            )}
            <p className="max-w-[180px] whitespace-nowrap overflow-hidden text-ellipsis relative">
              <Link to={actLink} onClick={handleActLink}>
                {data.title}
              </Link>
            </p>
          </div>
        );
      },
      className: "body-xl py-[15px] h-[90px]",
    },
    {
      param: "CREATIONDATE",
      label: labels.creationDate,
      render: (data: ActsTableProps) => data.creationDate,
    },
    {
      param: "EXPIRATIONDATE",
      label: labels.deadline,
      render: (data: ActsTableProps) => {
        return (
          <div className="flex items-center">
            {data.expiresIn && data.expiresIn <= 7 && (
              <Icon
                type="warning"
                color={data.expiresIn <= 3 ? "#F60000" : "#F6A300"}
                className="w-4 h-4 inline-block mr-2"
              />
            )}
            {data.expirationDate}
          </div>
        );
      },
    },
    {
      label: labels.status,
      render: (data: ActsTableProps) => (
        <div className="flex flex-col 2xl:flex-row 2xl:items-center items-start">
          {"SIGNING" !== data.signingStatus ? (
            <div className="flex items-center justify-center">
              {(data.status === "closed" || data.status === "cancelled") && (
                <Icon
                  type={
                    data.closedAndArchived ? "circle-check" : "close-circle"
                  }
                  className="size-[15px] mr-[6px]"
                  color="#25233D"
                />
              )}
              <span className="body-md">{data.state}</span>
            </div>
          ) : (
            <>
              {data.currentStep && data.maxSteps ? (
                <>
                  <div className="min-w-[107px]">
                    <ProgressBar
                      currentStep={data.currentStep}
                      maxSteps={data.maxSteps}
                    />
                  </div>
                  <p className="2xl:ml-2 2xl:mt-0 mt-2 text-[10px]">
                    {data.state}
                  </p>
                </>
              ) : (
                <>
                  <p className="2xl:mt-0 mt-2 text-[10px]">{data.state}</p>
                </>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      label: "",
      render: (data: ActsTableProps) => {
        const mappedType = actTypeNameMap[data.actType as string];
        const mappedTypeDcm = actTypeNameMap[data.businessSubType as string];
        const type = undefined !== mappedTypeDcm ? mappedTypeDcm : mappedType;

        const actLink = getActLink(
          {
            type: data.actType || "",
            status: data.status || "",
            signingStatus: data.signingStatus || "",
            publicId: data.id || "",
            subType: data.businessSubType || "",
          },
          returnTabUrl,
        );

        const getButtonOptions = (
          status: string | undefined,
          type: string | undefined,
        ) => {
          let buttonOptions: string[];

          switch (status) {
            case "created":
            case "shared":
              buttonOptions = ["view", "history", "cancel", "duplicate"];
              break;
            case "signing":
              buttonOptions = [
                "relaunch",
                "view",
                "history",
                "cancel",
                "duplicate",
              ];
              break;
            case "waitingForPayment":
              buttonOptions = ["pay", "view", "history", "cancel", "duplicate"];
              break;
            case "cancelled":
            case "expired":
              buttonOptions = ["duplicate", "view", "history"];
              break;
            case "closed":
              // Map the act type to the correct amount
              const amount = actMapAmount[type as keyof typeof actMapAmount];
              const actAmount = Number(
                settings[amount as keyof typeof settings],
              );
              buttonOptions = ["download", "view", "history", "duplicate"];
              if (actAmount > 0) {
                buttonOptions.push("downloadInvoice");
              }
              break;
            default:
              buttonOptions = ["download", "view", "history", "duplicate"];
          }

          return buttonOptions;
        };

        const actionsMap: Record<string, ButtonDropdownOptions> = {
          download: {
            label: labels.downloadEvidence,
            icon: "download",
            onClick: () => {
              if (data?.invoiceId) {
                downloadPDF([data?.invoiceId]);
              }
            },
          },
          pay: {
            label: labels.payAct,
            onClick: () => {},
          },
          relaunch: {
            label: labels.relaunch,
            onClick: () => {},
            icon: "paper-plane",
          },
          view: {
            label: labels.openAct,
            onClick: () => {
              if (data.wasOpened) {
                navigate(actLink);
                return;
              }
              axios
                .put(`/api/v1/signbooks/${data.id}`, {
                  name: data.title,
                  description: data.description,
                  expirationDate: data.expirationDateTimestamp,
                  type: data.actType,
                  mustReadAllDocumentPages: data.mustReadAllDocumentPages,
                  wasOpened: true,
                })
                .then(() => {
                  navigate(actLink);
                })
                .catch((error) => {
                  if (error.response.data.length > 0) {
                    error.response.data.forEach((error: any) => {
                      toast.error(error.message, toastOptionsError);
                    });
                    return;
                  }

                  toast.error(
                    axiosErrorMessages[error.message],
                    toastOptionsError,
                  );
                });
            },
          },
          history: {
            label: labels.viewHistory,
            link: addQueryParams(
              `/acts/${type}/act-details/${data.id}`,
              {
                returnPath: returnTabUrl,
              },
              "act-history",
            ),
          },
          duplicate: {
            label: labels.duplicateDocument,
            onClick: () => {
              setDuplicating(data.id);

              axios
                .post(`/api/v1/signbooks/${data.id}/duplicate`)
                .then((response) => {
                  const { publicId, type, businessSubType } = response.data;
                  const normalizedType =
                    null !== businessSubType
                      ? actTypeNameMap[businessSubType]
                      : actTypeNameMap[type];

                  navigate(`/acts/${normalizedType}/${publicId}/information`);

                  setDuplicating(null);
                })
                .catch((error) => {
                  setDuplicating(null);
                  toast.error(
                    axiosErrorMessages[error.message],
                    toastOptionsError,
                  );
                });
            },
            icon: "duplicate",
          },
          cancel: {
            label: labels.cancelAct,
            onClick: (_: null, event: any) => {
              const $closestCell = event.target.closest("[data-index]");
              const index = parseInt($closestCell.dataset.index);

              if (isNaN(index)) {
                return;
              }
              dispatch(
                setModalData({
                  actToUpdate: dataForTable[index],
                  index,
                  title: labels.cancelationOfAct,
                  ctaButtonLabel: labels.cancelTheAct,
                  publicId: dataForTable[index].id,
                }),
              );
              dispatch(showModal("confirm"));
            },
          },
          downloadInvoice: {
            label: labels.downloadInvoice,
            onClick: () => {},
          },
        };

        // Generate the button options based on the status of the act.
        const buttonOptions = getButtonOptions(data.status, data.actType)
          .filter((option) => {
            // Remove duplicate option from digital acts.
            if ("numerise" === data.actType && "duplicate" === option) {
              return false;
            }

            // History should only be visible for act creator.
            if ("history" === option) {
              return data.creator === user.publicId;
            }

            return true;
          })
          .map((option) => {
            return actionsMap[option];
          });

        // Store the first button option as the main action
        // and remove it from the list for the dropdown.

        const mainAction = buttonOptions.shift();

        return (
          <ButtonDropdown
            options={buttonOptions}
            buttonLabel={mainAction?.label || ""}
            iconType={mainAction?.icon}
            onClick={() => {
              if (mainAction?.onClick) {
                mainAction.onClick();
              }

              if (mainAction?.link) {
                navigate(mainAction.link);
              }
            }}
            disabled={duplicating !== null}
            loading={duplicating === data.id}
          />
        );
      },
      className: "text-right [&>div]:mr-[10px]",
    },
  ];

  // Conditionally remove the EXPIRATIONDATE column if "digital" is present in the link
  if (containsDigital) {
    const expirationDateColumnIndex = columns.findIndex(
      (column) => column.param === "EXPIRATIONDATE",
    );

    if (expirationDateColumnIndex !== -1) {
      columns.splice(expirationDateColumnIndex, 1);
    }
  }

  const sortableColumns = [
    {
      label: labels.title,
      rowKey: "title",
    },
    {
      label: labels.creationDate,
      rowKey: "creationDate",
      dataType: "date",
      dataFormat: "shortDate",
    },
    {
      label: labels.deadline,
      rowKey: "expirationDate",
      dataType: "date",
      dataFormat: "shortDate",
    },
  ];

  return (
    <div className="act-table">
      <Table
        columns={columns}
        rows={dataForTable}
        sortableColumns={sortableColumns}
      />
    </div>
  );
};

export default ActsTable;
